import React, { useContext } from 'react';
import { useBook, useUsers, useIsModerator, KEYS, sortEntries } from '../GameData.js';
import PresentedPage from './PresentedPage.js';
import { useHeightTransition } from '../transitions.js';
import { LocalGameContext } from '../LocalGameContext.js';


const PresentedBook = ({ presentation }) => {
  const book = useBook(presentation.book);
  const users = useUsers();
  const isModerator = useIsModerator();
  const [user, ,] = useContext(LocalGameContext);

  const inc = () => {
    presentation.ref.update({page: presentation.page + 1});
  }
  const dec = () => {
    presentation.ref.update({page: presentation.page - 1});
  }

  const grantBookOwner = () => {
    const updateObj = {};
    updateObj[KEYS.user] = book.startUser;
    presentation.ref.update(updateObj);
  }

  const ownerButtons =  presentation[KEYS.user] || book.startUser === user.uid ? null : <>
    &nbsp;&nbsp;&nbsp;<button onClick={grantBookOwner}>Give book owner page controls</button>
  </>;

  const nextPageButton = <>
    <button onClick={inc} disabled={presentation.page + 1 >= Object.keys(book[KEYS.moves] || {}).length}>Show next page</button>
    &nbsp;
    <button onClick={dec} disabled={presentation.page <= 0}>Hide page</button>
  </>;

  let showNextPageButton = false;
  if(presentation[KEYS.user]) {
    showNextPageButton = user.uid === presentation[KEYS.user];
  }
  else {
    showNextPageButton = isModerator;
  }

  const presentationControls = <div>
    { showNextPageButton && nextPageButton }
    { isModerator && ownerButtons }
  </div>;

  const moveEntries = Object.entries(book[KEYS.moves] || {});
  const moves = moveEntries.sort(sortEntries).map((moveEntry, i) => {
    const move = moveEntry[1];
    move.key = moveEntry[0];
    move.pageIndex = i;
    return move;
  });
  const shownMoves = moves.filter(move => move.pageIndex <= presentation.page).reverse();
  const [transitions] = useHeightTransition(shownMoves, item => item.key);

  return (
    <div className="presentationSection">
      { presentationControls }
      <ul className={book.s ? book.s + " presentationSet rounded" : "presentationSet rounded"}>
        { transitions.mapItem(PresentedPage, {pageCount: moves.length, users}) }
      </ul>
    </div>
  );
};
  
export default PresentedBook;