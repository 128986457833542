import React, { useState, useEffect, useContext } from 'react';
import { LocalGameContext } from '../LocalGameContext.js';

const RoomCode = () => {
  const [, context] = useContext(LocalGameContext);
  const [seconds, setSeconds] = useState(0);

  const show = () => {
    setSeconds(15);
    if ('URLSearchParams' in window) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("room", context.roomName);
      window.history.replaceState(null, '', window.location.pathname + '?' + searchParams.toString());
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds(s => {
        const newS = Math.max(0, s - 1);
        if(s === 1) {
          if ('URLSearchParams' in window) {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.delete("room");
            window.history.replaceState(null, '', window.location.pathname + (searchParams.toString() ? '?' + searchParams.toString() : ''));
          }
        }
        return newS;
      });
    }, 1000);
    return () => clearInterval(timer);
  }, [setSeconds, context.roomName]);

  return (
    <div className="roomCode">
      { seconds > 0 && <div className="roomCodeCode">{ context.roomName }</div> }
      { seconds > 0 && <div className="roomCodeInstructions">Will hide in {seconds}...</div> }
      { seconds === 0 && <button onClick={ show }>Show room code</button> }
    </div>
  );
};
  
export default React.memo(RoomCode);