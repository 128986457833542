import React, { useRef, useLayoutEffect, useState, useEffect, useCallback, useContext } from 'react';
import { Painterro } from 'painterro';
import PageTimer from './PageTimer.js';
import { imageEndpoint, usePledge, KEYS } from '../GameData.js';
import { LocalGameContext } from '../LocalGameContext.js';

const PageEntryImage = ({ item, handleFinalSubmit, options, nextAction, towerLowerUrl }) => {
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [elapsed, setElapsed] = useState(0);
  const canvasRef = useRef();
  const painterroRef = useRef();
  const handleSaveRef = useRef();
  const pledgeAmount = usePledge();
  const [, context] = useContext(LocalGameContext);

  const imageTimer = (options && parseInt(options["ti"])) || null;
  
  const handleSubmit = (e) => {
    painterroRef.current && painterroRef.current.save();
  };

  const handleDrop = useCallback(e => {
    if(e.dataTransfer);
    const items = e.dataTransfer.items;
    if(!painterroRef.current || !items) return;
    
    let imgIndex = -1;
    for(let i = 0; i < items.length; i++) {
      if(items[i].type.startsWith("image")) {
        imgIndex = i;
        break;
      }
    }
    if(imgIndex === -1) return;
    e.preventDefault();
    e.stopImmediatePropagation();

    if(pledgeAmount < 350) {
      console.log("Attempt to drop with insufficient pledge");
      return;
    }
    const blob = items[imgIndex].getAsFile();
    const source = (window.URL || window.webkitURL).createObjectURL(blob);
    painterroRef.current.pasteInto(source);
  }, [pledgeAmount]);

  const handlePaste = useCallback(e => {
		if (e.clipboardData) {
      const items = e.clipboardData.items;
      if(!painterroRef.current || !items) return;

      let imgIndex = -1;
      for(let i = 0; i < items.length; i++) {
        if(items[i].type.startsWith("image")) {
          imgIndex = i;
          break;
        }
      }
      if(imgIndex === -1) return;
      e.preventDefault();
      e.stopImmediatePropagation();

      if(pledgeAmount < 350) {
        console.log("Attempt to paste with insufficient pledge");
        return;
      }
      const blob = items[imgIndex].getAsFile();
      const source = (window.URL || window.webkitURL).createObjectURL(blob);
      painterroRef.current.pasteInto(source);
		}
  }, [pledgeAmount]);

  const handleUndo = useCallback(e => {
    if (!(e.ctrlKey && e.key.toLowerCase() === 'z')) {
      return;
    }
    if(e.target.nodeName === "INPUT") {
      return;
    }
    e.preventDefault();
    e.stopImmediatePropagation();
    if(e.shiftKey) {
      painterroRef.current.worklog.redoState();
    }
    else {
      painterroRef.current.worklog.undoState();
    }
  }, []);
  
  useEffect(() => {
    window.addEventListener('paste', handlePaste);
    window.addEventListener('drop', handleDrop);
    window.addEventListener('keydown', handleUndo);

    return () => {
      window.removeEventListener('paste', handlePaste);
      window.removeEventListener('drop', handleDrop);
      window.removeEventListener('keydown', handleUndo);
    };
  }, [handlePaste, handleDrop, handleUndo]);

  handleSaveRef.current = (image, done) => {
    setSubmitDisabled(true);
    const formData = new FormData();
    formData.append('image', image.asBlob());
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.open('POST', imageEndpoint + "?v=2&move=" + item.key, true);
    xmlhttp.onreadystatechange = function() {
      if (xmlhttp.readyState === XMLHttpRequest.DONE ) {
        if(xmlhttp.status === 200 && xmlhttp.responseText.substr(0,4) === "http"){
          window.localStorage.removeItem("cacheImageId");
          window.localStorage.removeItem("cacheImageData");
          handleFinalSubmit(item, {}, { nextAction });
        }
        else {
          alert('Error submitting move: ' + xmlhttp.status + " " + xmlhttp.responseText);
          setSubmitDisabled(false);
        }
        done(true);
      }
    }
    xmlhttp.send(image.asBlob());
  };

  const handleChange = useCallback((painterroState) => {
    setSubmitDisabled(painterroState.operationsDone === 0 || painterroState.selection);
    if(painterroState.operationsDone > 0 && painterroState.operationsDone % 5 === 0) {
      window.localStorage.setItem("cacheImageId", item.key);
      window.localStorage.setItem("cacheImageData", painterroState.image.asDataURL());
    }
  }, [item]);

  useEffect(() => {
    const timer = setInterval(() => setElapsed(s => s + 1), 1000 * 60);
    return () => clearInterval(timer);
  }, [setElapsed]);

  useLayoutEffect(() => {
    if(!canvasRef || !canvasRef.current) return;
    if(!item || !item.key) return;

    if(painterroRef.current) return;
    const handleSave = (image, done) => {
      handleSaveRef.current(image, done);
    }

    let canvasSize = '600x450';
    if(towerLowerUrl) {
      canvasSize = '600x650'; // top 200 pixels visible
    }
    if(item.isBigImage) {
      canvasSize = '900x700';
    }

    painterroRef.current = Painterro({
      defaultTool: 'brush',
      hiddenTools: ['select', 'crop', 'arrow', 'stamp', 'resize', 'rotate', 'pixelize', 'save', 'open', 'close', 'settings'],
      id: canvasRef.current.id,
      saveHandler: handleSave,
      changeHandler: handleChange,
      activeFillColor: '#00ff00',
      activeFillColorAlpha: 1,
      defaultSize: canvasSize,
      defaultFontSize: 60,
      customEmojis: context.stamps,
    });

    const cacheImageId = window.localStorage.getItem("cacheImageId");
    //let toCancel;
    if(cacheImageId && cacheImageId === item.key) {
      /*toCancel =*/ setTimeout(() => painterroRef.current.show(window.localStorage.getItem("cacheImageData")), 50);
    }
    else {
      /*toCancel =*/ setTimeout(() => painterroRef.current.show(), 50);
      if(item[KEYS.base]) {
        setTimeout(() => painterroRef.current.insertInto(`https://www.brokenpicturephone.com/img/scribbles/${item[KEYS.base]}.png`, {}), 52);
      }
      if(towerLowerUrl) {
        setTimeout(() => painterroRef.current.insertInto(towerLowerUrl, {y: 450}), 52);
      }
    }
  }, [canvasRef, handleSaveRef, handleChange, item, towerLowerUrl, context.stamps]);

  return (<>
    <div id={"drawDiv" + item.key} className={towerLowerUrl ? "drawDiv hasTower" : "drawDiv"} ref={canvasRef}>
      { !imageTimer && elapsed >= 4 && <div className="elapsed">{ elapsed } minutes old</div> }
    </div>
    { (towerLowerUrl !== null) && <div style={{position: "absolute", width: "700px", height: "1px", backgroundColor: "red", top: "200px"}} />}
    <button onClick={handleSubmit} disabled={submitDisabled}>Submit</button>
    {imageTimer && <PageTimer id={item.key} seconds={imageTimer} onExpire={handleSubmit} />}
  </>);
};

export default React.memo(PageEntryImage);