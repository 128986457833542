import React from 'react';
import { useTransition, animated } from 'react-spring';
import { useBooksList, usePresentation, imageUrl, useIsModerator, KEYS } from '../GameData.js';


const PresentedImageGrid = () => {
  const books = useBooksList();

  const pageEntries = books.flatMap(b => Object.entries(b[KEYS.moves]));
  const images = pageEntries.filter(pe => pe[1][KEYS.finishedTime] && pe[1][KEYS.moveType] === "image").map(pe => pe[0]);

  
  const transitions = useTransition(images, item => item, {
    unique: true,
    trail: 400 / images.length,
    from: { opacity: 0, transform: 'scale(0)' },
    enter: { opacity: 1, transform: 'scale(1)' },
  });

  return (
    <div className="presentationImageGrid">
      { transitions.map(({item, key, props}) => <animated.img alt="page" key={ key } src={ imageUrl(item) } style={ props } />)}
    </div>
  );
};

export const PresentedImageGridButton = () => {
  const presentation = usePresentation();
  const isModerator = useIsModerator();

  if(!isModerator) return null;
  if(presentation.imageGrid) return <button onClick={ () => presentation.ref.remove() }>Hide all images</button>;
  return <button onClick={ () => presentation.ref.set({ imageGrid: true }) }>Present all images</button>;
};
  
export default PresentedImageGrid;