import React from 'react';
import PresentationSection from './sections/PresentationSection.js';
import UserSection from './sections/UserSection.js';
import BookListSection from './sections/BookListSection.js';
import WaitingSection from './sections/WaitingSection.js';
import { useIsPlayer } from './GameData.js';


const RoomContainer = () => {
  const isPlayer = useIsPlayer();
  return (
    <>
      { isPlayer ? <PresentationSection /> : <WaitingSection /> }
      <UserSection />
      { isPlayer ? <BookListSection /> : null }
    </>
  );
};
  
export default RoomContainer;