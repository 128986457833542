import React, { useState } from 'react';
import { KEYS, sortPages } from '../GameData.js';

const BookListEntry = ({ book, user, users, presentation, assignBook }) => {
  const [expanded, setExpanded] = useState(false);

  const present = () => {
    presentation.ref.set({
      book: book.key,
      page: 0,
    });
  }
  const dismiss = () => {
    presentation.ref.remove();
  }

  if(!book || !book[KEYS.moves]) {
    return null;
  }

  const startingUser = users && users[book.startUser];
  const isComplete = !!(book.finishedTime);
  const currentUser = !isComplete && users && book.activePage && users[book[KEYS.moves][book.activePage][KEYS.user]];
  const atInactiveUser = currentUser && (!currentUser.isActive || currentUser.accessLevel < 40);
  const pageCount = Object.keys(book[KEYS.moves]).length;
  const isModerator = users && users[user.uid] && users[user.uid].accessLevel >= 80;

  const classes = ["bookEntry rounded"];
  if(presentation && presentation.book === book.key) classes.push("bookBeingPresented");
  if(atInactiveUser) classes.push("bookAtInactiveUser");

  let subItem = null;
  if(isComplete) {
    if(isModerator) {
      subItem = <span> - { presentation && presentation.book === book.key
        ? <button onClick={dismiss}>Dismiss</button>
        : <button onClick={present}>Present</button>
        }</span>
    }
  } else if(!expanded) {
    subItem = <span className="bookPage"> - pg{pageCount}: {currentUser && currentUser.displayName}</span>;
  }

  return (<>
      <li className={ classes.join(" ") } >
        <span className="bookTitle" onClick={() => setExpanded(!expanded)}>{ startingUser && startingUser.displayName }</span>
        { subItem }
        { expanded && <div className="bookExpanded">
          <ul>{Object.values(book[KEYS.moves]).sort(sortPages).map(m => m[KEYS.user]).map(uid => uid === "c" ? "Computer" : users[uid]).filter(u => u).map((u, i) =>
            <li key={i}>pg{i+1}: {u.displayName || u}</li>
          )}</ul>
        </div> }
        {!isComplete && isModerator && (expanded || atInactiveUser) && <button onClick={() => assignBook(book, {isReassign: true})}>Reassign</button>}
      </li>
    </>
  );
};
  
export default BookListEntry;