import React, { useContext, useRef, useEffect } from 'react';
import './PatreonSection.css';
import patreonImg from '../img/Patreon_Navy.png';
import { usePledge, useUserSelf } from '../GameData';
import { LocalGameContext } from '../LocalGameContext.js';
import UserColorChanger from './UserColorChanger';

const PatreonSection = () => {
  const pledgeAmount = usePledge();
  const userSelf = useUserSelf();
  const [user, ,] = useContext(LocalGameContext);
  
  const oldAmount = useRef();
  useEffect(() => {
    if(!userSelf || !userSelf.ref) return;
    if(pledgeAmount && !oldAmount.current) {
      oldAmount.current = pledgeAmount;
      userSelf.ref.child("tag").set("https://www.brokenpicturephone.com/img/ptag.png");
    }
  }, [pledgeAmount, userSelf]);

  const openPledgeWindow = () => {
    const url = `https://www.patreon.com/oauth2/authorize?response_type=code&client_id=Yg96Hx2lMJh9Z9CzT5QvOPr0lf63AvRQ5gGCNjeTvh78goHRnT0l8UaBWFpQv9rn&state=${user.uid}&scope=identity&redirect_uri=https%3A%2F%2Fus-central1-project-474778063223158103.cloudfunctions.net%2Fapp%2Fpatreon%2Fvalidate`;
    window.open(url, "oauth2target", "innerWidth=600,innerHeight=800,left=100,top=100");
  };

  const playerLimitDesc = (<div className="desc limits">
    <div>Current player limits:</div>
    <div><span className="line">Normal rooms: <b>8</b>, </span><span className="line">Patreon rooms: <b>14</b></span></div>
  </div>);

  const lessThan300 = (
    <div className="desc">"Beta-Tester" Patrons can paste images into pages and change the color of their username in the user list.</div>
  );

  const yesPaste = (
    <div className="desc"><b>Paste unlocked!</b> Use Ctrl+V/Cmd+V or drag to paste an image into your drawing.</div>
  );
  
  const yesPledge = (
    <div className="support">
      <div>Thank you for your ${Math.floor(pledgeAmount/100)} support <a href="https://patreon.com/stochaztic">via Patreon</a>!</div>
      <h3>Username color:</h3>
      {pledgeAmount >= 300 ? <UserColorChanger /> : lessThan300 }
      {pledgeAmount >= 350 ? yesPaste : null }
      <div className="desc">Additional features will be coming soon.</div>
      <button onClick={ openPledgeWindow }>Reconnect</button>
      {playerLimitDesc}
    </div>
  )

  const noPledge = (
    <div className="support">
      <a href="https://patreon.com/stochaztic"><img src={patreonImg} alt="Patreon logo" /></a>
      <div>You can support Broken Picturephone <a href="https://patreon.com/stochaztic">via Patreon</a>. Connect your pledge to 
        get a <b>higher player limit</b> and other special features!
      </div>
      <button onClick={ openPledgeWindow }>Connect your pledge</button>
      {playerLimitDesc}
    </div>
  );

  return pledgeAmount ? yesPledge : noPledge;
};
  
export default PatreonSection;