import React, { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { FirebaseAppProvider } from 'reactfire';
import { LocalGameProvider } from './LocalGameContext.js';

const config = {
  apiKey: "AIzaSyAfUjfSbJjGu-NHpKqbtWRyFhGsNWq6miI",
  authDomain: "brokenp2.firebaseapp.com",
  databaseURL: "https://brokenp2.firebaseio.com",
  projectId: "project-474778063223158103",
  storageBucket: "project-474778063223158103.appspot.com",
  messagingSenderId: "219510370515",
  appId: "1:219510370515:web:ca4432c274ce5bdc"
};

// eslint-disable-next-line
const demoConfig = {
  apiKey: "AIzaSyDpWVBAY4730mjb3DcYOXt-H7npMkgGNQw",
  authDomain: "jh-test-277ec.firebaseapp.com",
  databaseURL: "https://jh-test-277ec.firebaseio.com",
  projectId: "jh-test-277ec",
  storageBucket: "jh-test-277ec.appspot.com",
  messagingSenderId: "815401988288",
  appId: "1:815401988288:web:2274ddb307ffb17e"
};

ReactDOM.render(
  <StrictMode>
    <Suspense fallback={<p>loading...</p>}>
      <FirebaseAppProvider firebaseConfig={config}>
        <LocalGameProvider>
          <App />
        </LocalGameProvider>
      </FirebaseAppProvider>
    </Suspense>
  </StrictMode>
  , document.getElementById('root'));