import React, { useState, useContext, useEffect, useRef } from 'react';
import './RoomChooser.css';
import patreonImg from './img/Patreon_Navy.png';
import { KEYS } from './GameData.js';
import { LocalGameContext } from './LocalGameContext.js';
import { useFirebaseApp } from 'reactfire';
import 'firebase/database';


const RoomChooser = () => {
  const [displayName, setDisplayName] = useState("");
  const [roomName, setRoomName] = useState("");
  const [joining, setJoining] = useState(false);
  const [user, , setContext] = useContext(LocalGameContext);
  const firebaseApp = useFirebaseApp();
  const roomsRef = firebaseApp.database().ref(KEYS.rooms);
  const joinAttemptCount = useRef(0);
  const [lang, setLang] = useState('en');

  const setSaveLang = (str) => {
    window.localStorage.setItem("lang", str);
    setLang(str);
  }

  const joinRoom = (e) => {
    setJoining(true);
    firebaseApp.database().ref("v").on("value", (snap) => {
      if(snap.val() !== true) {
        setContext(s => ({...s, version: snap.val()}));
      }
    });
    const roomKey = roomName.toLowerCase().replace(/[^\w]/gi, '').substring(0, 20);
    window.localStorage.setItem("displayName", displayName);
    window.localStorage.setItem("roomName", roomKey);
    roomsRef.child(roomKey).child(KEYS.users).child(user.uid).child('isActive').once('value').then(snapshot => {
      if(joinAttemptCount.current < 1 && snapshot.val()) {
        alert("You already have a window open in this room. Please only open each room once.\n\nIf you believe this message to be in error, you may attempt to join again to override. Please be careful: if you do actually have multiple windows open, game corruption may occur.");
        setJoining(false);
        joinAttemptCount.current = joinAttemptCount.current + 1;
      } else {
        roomsRef.child(roomKey).update({creator: user.uid})
        .then(() => joinRoomStep2(roomKey, true))
        .catch(() => joinRoomStep2(roomKey, false));
      }
    });
  };

  const joinRoomStep2 = (roomKey, isAdmin = false) => {
    if ('URLSearchParams' in window) {
      const searchParams = new URLSearchParams(window.location.search);
      if(searchParams.has("room")) {
        searchParams.delete("room");
        window.history.replaceState(null, '', window.location.pathname + (searchParams.toString() ? '?' + searchParams.toString() : ''));
      }
    }
    firebaseApp.database().ref(".info/connected").on("value", (snap) => {
      if(snap.val() !== true) {
        setContext(s => ({...s, disconnected: true}));
      }
    });
    const userRef = roomsRef.child(roomKey).child(KEYS.users).child(user.uid);
    userRef.child("isActive").onDisconnect().set(false).then(() => {
      return userRef.child('accessLevel').once('value');
    }).then(snapshot => {
      const accessLevel = snapshot.val() || (isAdmin ? 100 : 20);
      return userRef.update({
        accessLevel,
        displayName,
        isActive: true,
      }).then(() => {
        setContext(s => ({...s, displayName, roomName: roomKey}));
      }).catch((error) => console.log(error));
    });
  }

  useEffect(() => {
    setDisplayName(window.localStorage.getItem("displayName") || "");
    let newRoomName = window.localStorage.getItem("roomName") || "";
    if ('URLSearchParams' in window) {
      const searchParams = new URLSearchParams(window.location.search);
      newRoomName = searchParams.get("room") || newRoomName;
    }
    setRoomName(newRoomName);

    if(window.localStorage.getItem("lang")) {
      setLang(window.localStorage.getItem("lang"));
    }
    else if(window.navigator && window.navigator.language === "pt-BR"){
      setLang("pt-BR");
    }
    else {
      // default state is already en
    }
  }, []);

  const langChooser = <div className="languageChooser">
    <span onClick={() => setSaveLang("en")}>en</span><span onClick={() => setSaveLang("pt-BR")}>pt-BR</span>
  </div>;

  const creditsBlock = <div className="descriptionSection rounded">
    <p><strong>Special thanks to:</strong> AndyPerfect, CobaltCustard, Ryan Alyea, Tom Fawkes, Adam Swanson, 
      Doctor Swellman, Jonathan Evans, Brandon Robert Jackson, Kerwin Mendoza, Adrienne Kisner,
      maddie, Taylor Simon, Rehaan Jahanghir, Roberto M. Vilas Bôas, Creative Corrputions, Lin, Scott Denby, Anika Buehler </p>
  </div>

  const adBlock = <>
<ins className="adsbygoogle" style={{display:"block", textAlign:"center"}}
  data-ad-layout="in-article"
  data-ad-format="fluid"
  data-ad-client="ca-pub-3669262654087643"
  data-ad-slot="6499061484"></ins>
<script>
  (adsbygoogle = window.adsbygoogle || []).push({});
</script></>;

  const enBlock = <>
    <div className="descriptionSection rounded">
      <h3>Welcome!</h3>
      {langChooser}
      <p>Welcome to Broken Picturephone, the live game where you and your friends create books of drawings and phrases, one page 
      at a time, only being able to see one previous page. Then, everyone experiences each full book together!</p>
      {adBlock}
      <h3>To Play</h3>
      <p>Choose a display name to proceed. We suggest a short name, like your first name, recognizable to your friends.
        Then, either create a room and invite your friends to join, or join an existing room of your friends on the waitlist. The 
        room's creator can make any waitlisted person an active player or moderator. Just use a unique room code phrase for your group of friends.</p>
      <p>Once you have all of your friends in the room, a moderator can give each player a new book. Start off by making up a phrase
        or drawing. As soon as you have finished your page, the book will be sent to another active player. You are also going to 
        receive books from the other players! Using only the previous page, make the best phrase or drawing to match that you can.</p>
      <p>Once a book has a page from every active player, it is complete. When all books are complete, a chime sounds. Now it's time
        to present the book! The moderators can control the presentation, so everyone gets to see each page of each book at the same 
        time. Once everyone has looked at all of the books, the moderators can create new books and begin a new round!</p>
      <h3>Chat</h3>
      <p>Broken Picturephone is best experienced when everyone can laugh and discuss their favorite drawings and phrases during the 
        presentation. We highly recommend your friends get together in Discord or some other chat program to enjoy the game!</p>
      <h3>Contact</h3>
      <p>You can reach me on Twitter <a href="http://www.twitter.com/stochaztic">@stochaztic</a>.</p>
      <div className="patreonContainer">
        <a href="https://patreon.com/stochaztic"><img src={patreonImg} alt="Patreon logo" /></a>
        <div><p>Want to support Broken Picturephone? You can do so <a href="https://patreon.com/stochaztic">via Patreon</a>, and get some neat perks as a bonus:</p>
          <ul>
            <li>A higher player limit per room</li>
            <li>An icon next to your username to indicate your support</li>
            <li>Customize the color of your name in the user list</li>
            <li>Copy and paste images into your drawings</li>
          </ul>
        </div>
      </div>
      <p><small><small><a href="terms.html">Terms of Use / Privacy Policy</a></small></small></p>
    </div>  
  </>;

  const ptBrBlock = <>
    <div className="descriptionSection rounded">
      <h3>Bem-vindo(a)!</h3>
      {langChooser}
      <p>Bem-vindo(a) ao Broken Picturephone, o jogo em tempo real no qual você e seus amigos criam
        livros de desenhos e frases, uma página de cada vez, só podendo ver uma página anterior.
        Depois, todos experimentam cada livro completo juntos!</p>
      {adBlock}
      <h3>Para Jogar</h3>
      <p>Escolha um nome de exibição para prosseguir. Recomendamos um nome curto, como seu
        primeiro nome, que seja reconhecível por seus amigos. Depois, crie uma sala e convide seus
        amigos para participar, ou entre em uma sala já existente de seus amigos na lista de espera. O
        criador da sala pode tornar qualquer pessoa da lista de espera em um jogador ou moderador
        ativo. É só usar um código de sala único para seu grupo de amigos.</p>
      <p>Quando todos os seus amigos estiverem na sala, um moderador pode dar a cada jogador um
        novo livro. Para começar, invente uma frase ou desenho. Quando terminar sua página, o livro
        será enviado a outro jogador. Você também receberá livros de outros jogadores! Usando
        apenas a página anterior, crie a melhor frase ou desenho para combinar que você puder.</p>
      <p>Quando um livro tiver uma página de cada jogador ativo, ele estará completo. Quando todos os
        livros estiverem completos, um som de campainha tocará. Agora é a hora de apresentar o livro!
        Os moderadores podem controlar a apresentação, para que todos possam ver cada página de
        cada livro ao mesmo tempo. Uma vez que todos tenham olhado todos os livros, os
        moderadores podem criar novos livros e começar uma nova rodada!</p>
      <h3>Bate-papo</h3>
      <p>O Broken Picturephone é melhor quando todos podem rir e discutir seus desenhos e frases
        favoritas durante a apresentação. Recomendamos que seus amigos se reúnam no Discord ou
        outro programa de bate-papo para curtir o jogo!</p>
      <h3>Contato</h3>
      <p>Você pode me contatar no Twitter <a href="http://www.twitter.com/stochaztic">@stochaztic</a>. 
        I only speak English, I am sorry!</p>
      <div className="patreonContainer">
        <a href="https://patreon.com/stochaztic"><img src={patreonImg} alt="Patreon logo" /></a>
        <div><p>Deseja apoiar o Broken Picturephone? Você pode fazer isso através do <a href="https://patreon.com/stochaztic">Patreon</a>, 
          e ganhar alguns benefícios legais como um bônus:</p>
          <ul>
            <li>Um número mais alto de jogadores por sala</li>
            <li>Um ícone ao lado de seu nome de usuário para indicar seu apoio</li>
            <li>Personalizar a cor de seu nome na lista de usuários</li>
            <li>Copiar e colar imagens em seus desenhos</li>
          </ul>
        </div>
      </div>
      <p><small><small><a href="terms.html">Termos de Uso / Política de Privacidade</a></small></small></p>
    </div>  
    <div className="noticeSection rounded">
      <p><strong>Teste Beta de "Copiar imagem no Desenho" disponível!</strong></p>
      <p>Os patrões do nível "Picturephone Beta-Testers" agora têm a opção de copiar e colar ou
        arrastar e soltar uma imagem em seus desenhos! Use suas próprias imagens "tipo selo" em
        muitos desenhos, ou faça todo o seu desenho em outro programa e importe-o para o BPP
        quando estiver pronto. Consulte a página 
        do <a href="https://patreon.com/stochaztic">Patreon</a> para obter mais detalhes ou para se
        inscrever.</p>
    </div>
  </>;

  return (
    <>
      <div className="roomSection rounded">
        <label>Display name:</label><input type="text" placeholder="Your display name" value={displayName} onChange={e => setDisplayName(e.target.value.substring(0, 20))}></input>
        <label>Room code:</label><input type="text" placeholder="Room code" value={roomName} onChange={e => setRoomName(e.target.value.replace(/[^\w]/gi, '').substring(0, 20))}></input>
        {joining ? <h4>Joining...</h4> : <button onClick={joinRoom} disabled={!(displayName && roomName && roomName.length >= 4)}>Create or Join Room</button>}
      </div>
      {lang === "pt-BR" ? ptBrBlock : enBlock}
      {creditsBlock}
    </>
  );
};
  
export default RoomChooser;