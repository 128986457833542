import React from 'react';
import './BookListSection.css';
import BookList from './BookList.js';
import RoomCode from './RoomCode.js';
import { PresentedImageGridButton } from './PresentationImageGrid.js';
import DownloadBooks from './DownloadBooks.js';
import PatreonSection from './PatreonSection.js';
import NewGameButton from './NewGameButton.js';

const BookListSection = () => {
  return (
    <div className="bookListSection rounded">
      <h3>Book List</h3>
      <BookList />
      <div style={{height: "10px"}} />
      <h3>Room Actions</h3>
      <RoomCode />
      <div style={{height: "4px"}} />
      <DownloadBooks />
      <div style={{height: "4px"}} />
      <PresentedImageGridButton />
      <div style={{height: "4px"}} />
      <NewGameButton />
      <PatreonSection />
    </div>
  );
};
  
export default React.memo(BookListSection);