import React from 'react';
import { useGameData, imageUrl, KEYS, sortEntries } from '../GameData.js';

const urlToBase64 = url => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
          resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    }));

const DownloadBooks = () => {
  const { books, users } = useGameData();
  const enabled = books.length > 0 && books.every(b => b.finishedTime);

  const download = async () => {
    document.body.classList.add("wait");
    const booksData = await Promise.all(books.map(async b => {
      const moveEntries = Object.entries(b[KEYS.moves] || {});
      const moves = moveEntries.sort(sortEntries).map((moveEntry, i) => {
        const move = moveEntry[1];
        move.key = moveEntry[0];
        move.pageIndex = i;
        return move;
      });
      const promises = moves.filter(m => m[KEYS.moveType] === "image").map(m => {
        return urlToBase64(imageUrl(m.key)).then(data => { m.data = data });
      });
      await Promise.all(promises);
      return `<article class="${b[KEYS.specialMode]}"><h2>${ users.name(b.startUser) }'s Book</h2>\n` + 
        moves.map(m => {
          const text = m.text 
              ? `<h4>${ m.text }</h4>`
              : `<img src="${ m.data }" />`;
          return `<section><h3>Page ${ m.pageIndex + 1 }, ${ users.name(m[KEYS.user])}:</h3>\n\t${ text }</section>`;
        }).join("\n") +
        `</article>`;
    }));
    const booksText = booksData.join("\n");

    const html = `<!DOCTYPE html>
      <html>
        <head>
          <link rel="stylesheet" href="https://www.brokenpicturephone.com/css/download2.css" />
          <title>Broken Picturephone Downloaded Book</title>
        </head>
        <body>
        <h1>Broken Picturephone - ${ (new Date()).toLocaleString() }</h1>
        ${ booksText }
        </body>
      </html>`;
    
    const el = document.createElement("a");
    const file = new Blob([html], { type: 'text/html', endings: 'native' });
    el.href = URL.createObjectURL(file);
    el.download = `bpp-${ (new Date()).getTime() }.html`;
    document.body.appendChild(el);
    el.click();
    document.body.classList.remove("wait");
  }

  return (
    <button onClick={ download } disabled={ !enabled }>Download books</button>
  );
};
  
export default React.memo(DownloadBooks);