import React, { useEffect, useContext } from 'react';
import './App.css';
import 'firebase/auth';
import { useFirebaseApp } from 'reactfire';
import RoomChooser from './RoomChooser.js';
import RoomContainer from './RoomContainer.js';
import { LocalGameContext } from './LocalGameContext.js';

const CURRENT_VERSION = 38;

const App = () => {
  const firebaseApp = useFirebaseApp();
  const [user, context, setContext] = useContext(LocalGameContext);

  useEffect(() => {
    window.addEventListener("dragover", function(event) {
      event.preventDefault();
    });
  }, []);

  useEffect(() => {
    firebaseApp.auth().signInAnonymously().catch(function(error) {
      console.log(error);
    });
  }, [firebaseApp]);

  useEffect(() => {
    try {
      const stampsUrl = new URLSearchParams(window.location.search).get("stamps");
      if(stampsUrl) {
        fetch(stampsUrl).then((response) => {
          return response.text().then((text) => {
            const lines = text.replace(/[\r"'<>]/g, '').split("\n").map(x => encodeURI(x.trim())).filter(x => x.startsWith("http"));
            if(lines.length > 0) {
              setContext(s => ({...s, stamps: lines}));
            }
          });
        });
      }
    }
    catch(e) {
      console.warn("Error when attempting to process stamp data");
      console.warn(e);
    }
  }, [setContext]);

  const disconnect = <h2>You have disconnected from Broken Picturephone. Please reload the page to reconnect.</h2>;

  const maintenance = <h2>Broken Picturephone is undergoing maintenance. Please wait to reconnect.</h2>;

  const upgrade = <h2>Broken Picturephone has been updated. Please reload the page to reconnect. If you still get this message, you may need to clear your browser cache to update to the latest version.</h2>;

  let appContent;
  if(context.disconnected) {
    appContent = disconnect;
  }
  else if(context.version === 0) {
    appContent = maintenance;
  }
  else if(!isNaN(context.version) && context.version > CURRENT_VERSION) {
    appContent = upgrade;
  }
  else {
    appContent = context.roomName ? <RoomContainer /> : user ? <RoomChooser /> : <h3>Logging in...</h3>
  }

  return (
      <div className={context.roomName ? "appContainer authYes" : "appContainer authNo"}>
        { appContent }
      </div>
  );
};

export default App;
