import React from 'react';
import { imageUrl, KEYS } from '../GameData.js';

const PresentedPage = React.forwardRef(({ item, pageCount, users }, ref) => {
  let moveSpan = <span className="moveMissing">(No page data.)</span>;
  if(item.text) {
    moveSpan = <span className="moveText">{ item.text }</span>;
  }
  else if(item[KEYS.moveType] === "image") {
    moveSpan = <span className="moveImage"><img className="pageImage" alt="page" src={ imageUrl(item.key) } /></span>;
  }
  return (
    <li ref={ ref } className="moveRow">
      <span className="moveUser">
        <span className="moveIndex">{ item.pageIndex + 1 }/{ pageCount }</span>
        { users.name(item[KEYS.user]) }
      </span>
      { moveSpan }
    </li>
  );
});

export default React.memo(PresentedPage);