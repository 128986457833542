import { tr } from './translation';
import { genId, KEYS, imgToDataURL } from './utils';

let instance = null;
export default class Inserter {
  constructor() {
    this.pasteOptions = {
      replace_all: {
        internalName: 'fit',
        handle: (img) => {
          this.main.fitImage(img);
        },
      },
      extend_down: {
        internalName: 'extend_down',
        handle: (img) => {
          this.tmpImg = img;
          const oldH = this.main.size.h;
          const oldW = this.main.size.w;
          const newH = oldH + img.naturalHeight;
          const newW = Math.max(oldW, img.naturalWidth);
          const tmpData = this.ctx.getImageData(0, 0, this.main.size.w, this.main.size.h);
          this.main.resize(newW, newH);
          this.main.clearBackground();
          this.ctx.putImageData(tmpData, 0, 0);
          this.main.adjustSizeFull();
          if (img.naturalWidth < oldW) {
            const offset = Math.round((oldW - img.naturalWidth) / 2);
            this.main.select.placeAt(offset, oldH, offset, 0, img);
          } else {
            this.main.select.placeAt(0, oldH, 0, 0, img);
          }
          this.main.setToolEnabled(this.main.toolByName.undo, false);
          this.main.setToolEnabled(this.main.toolByName.redo, false);
        },
      },
      extend_right: {
        internalName: 'extend_right',
        handle: (img) => {
          this.tmpImg = img;
          const oldH = this.main.size.h;
          const oldW = this.main.size.w;
          const newW = oldW + img.naturalWidth;
          const newH = Math.max(oldH, img.naturalHeight);
          const tmpData = this.ctx.getImageData(0, 0, this.main.size.w, this.main.size.h);
          this.main.resize(newW, newH);
          this.main.clearBackground();
          this.ctx.putImageData(tmpData, 0, 0);
          this.main.adjustSizeFull();
          if (img.naturalHeight < oldH) {
            const offset = Math.round((oldH - img.naturalHeight) / 2);
            this.main.select.placeAt(oldW, offset, 0, offset, img);
          } else {
            this.main.select.placeAt(oldW, 0, 0, 0, img);
          }
          this.main.setToolEnabled(this.main.toolByName.undo, false);
          this.main.setToolEnabled(this.main.toolByName.redo, false);
        },
      },
      paste_over: {
        internalName: 'over',
        handle: (img, opts = {}) => {
          this.tmpImg = img;
          const oldH = this.main.size.h;
          const oldW = this.main.size.w;
          if (img.naturalHeight <= oldH && img.naturalWidth <= oldW) {
            const x = opts.x !== undefined ? (opts.x) - (img.naturalWidth / 2) : 0;
            const y = opts.y !== undefined ? (opts.y) - (img.naturalHeight / 2) : 0;
            this.main.select.placeAt(
              x, y,
              (oldW - img.naturalWidth) - x,
              (oldH - img.naturalHeight) - y, img,
            );
          } else if (img.naturalWidth / img.naturalHeight > oldW / oldH) {
            const newH = oldW * (img.naturalHeight / img.naturalWidth);
            this.main.select.placeAt(0, 0, 0, oldH - newH, img);
          } else {
            const newW = oldH * (img.naturalWidth / img.naturalHeight);
            this.main.select.placeAt(0, 0, oldW - newW, 0, img);
          }
          this.main.setToolEnabled(this.main.toolByName.undo, false);
          this.main.setToolEnabled(this.main.toolByName.redo, false);
          this.main.select.nextTool = opts.nextTool;
        },
      },
    };
    this.activeOption = this.pasteOptions;
  }

  init(main) {
    this.CLIP_DATA_MARKER = 'painterro-image-data';
    this.ctx = main.ctx;
    this.main = main;
    this.worklog = main.worklog;
    this.selector = main.wrapper.querySelector('.ptro-paster-select-wrapper');
    this.cancelChoosing();
    this.img = null;
    Object.keys(this.pasteOptions).forEach((k) => {
      const o = this.pasteOptions[k];
      this.main.doc.getElementById(o.id).onclick = () => {
        if (this.loading) {
          this.doLater = o.handle;
        } else {
          o.handle(this.img);
        }
        this.cancelChoosing();
      };
    });
    this.loading = false;
    this.doLater = null;
  }

  insert(x, y, w, h) {
    this.main.ctx.drawImage(this.tmpImg, x, y, w, h);
    this.main.worklog.captureState();
  }

  cancelChoosing() {
    this.selector.setAttribute('hidden', '');
    this.waitChoice = false;
  }

  loaded(img) {
    this.img = img;
    this.loading = false;
    if (this.doLater) {
      this.doLater(img);
      this.doLater = null;
    }
  }

  handleInsert(src, opts = {}) {
    const handleIt = (source) => {
      const img = new Image();
      img.onload = () => {
        let w = opts.w || img.width;
        let h = opts.h || img.height;
        let x = opts.x || 0;
        let y = opts.y || 0;
        if (opts.fit) {
          const childRatio = img.width / img.height;
          const targetRatio = w / h;
          if (childRatio > targetRatio) {
            y += (h - (w / childRatio)) / 2;
            h = w / childRatio;
          } else {
            x += (w - (h * childRatio)) / 2;
            w = h * childRatio;
          }
        }
        this.main.ctx.drawImage(img, x, y, w, h);
        if (opts.canUndo) {
          this.main.worklog.captureState();
        } else {
          this.main.worklog.reCaptureState();
        }
      };
      img.src = source;
    };

    if (src.indexOf('data') !== 0) {
      imgToDataURL(src, (dataUrl) => { // if CORS will not allow,
        // better see error in console than have different canvas mode
        handleIt(dataUrl);
      });
    } else {
      handleIt(src);
    }
  }

  handleStamp(src, opts = {}) {
    this.startLoading();
    const handleIt = (source) => {
      const img = new Image();
      img.onload = () => {
        this.loaded(img);
        this.pasteOptions.paste_over.handle(img, opts);
        this.finishLoading();
      };
      img.src = source;
    };

    if (src.indexOf('data') !== 0) {
      imgToDataURL(src, (dataUrl) => { // if CORS will not allow,
        // better see error in console than have different canvas mode
        handleIt(dataUrl);
      });
    } else {
      handleIt(src);
    }
  }

  handleOpen(src) {
    this.startLoading();
    const handleIt = (source) => {
      const img = new Image();
      const empty = this.main.worklog.clean;
      img.onload = () => {
        if (empty) {
          this.main.fitImage(img);
        } else {
          this.loaded(img);
        }
        this.finishLoading();
      };
      img.src = source;
      if (!empty) {
        if (Object.keys(this.activeOption).length !== 1) {
          this.selector.removeAttribute('hidden');
          this.waitChoice = true;
        } else {
          this.doLater = this.activeOption[Object.keys(this.activeOption)[0]].handle;
        }
      }
    };

    if (src.indexOf('data') !== 0) {
      imgToDataURL(src, (dataUrl) => { // if CORS will not allow,
        // better see error in console than have different canvas mode
        handleIt(dataUrl);
      });
    } else {
      handleIt(src);
    }
  }

  handleKeyDown(evt) {
    if (this.waitChoice && evt.keyCode === KEYS.esc) {
      this.cancelChoosing();
    }
  }

  startLoading() {
    this.loading = true;
    const btn = this.main.doc.getElementById(this.main.toolByName.open.buttonId);
    const icon = this.main.doc.querySelector(`#${this.main.toolByName.open.buttonId} > i`);
    if (btn) {
      btn.setAttribute('disabled', 'true');
    }
    if (icon) {
      icon.className = 'ptro-icon ptro-icon-loading ptro-spinning';
    }
  }

  finishLoading() {
    const btn = this.main.doc.getElementById(this.main.toolByName.open.buttonId);
    const icon = this.main.doc.querySelector(`#${this.main.toolByName.open.buttonId} > i`);
    if (btn) {
      btn.removeAttribute('disabled');
    }
    if (icon) {
      icon.className = 'ptro-icon ptro-icon-open';
    }
  }

  static get() {
    /*
    if (instance) {
      return instance;
    }
    */
    instance = new Inserter();
    return instance;
  }

  activeOptions(actOpt) {
    const po = Object.keys(this.pasteOptions);
    po.forEach((i) => {
      let b = false;
      actOpt.forEach((k) => {
        if (i === k) {
          b = true;
        }
      });
      if (b === false) {
        delete this.pasteOptions[i];
      }
    });
    this.activeOption = this.pasteOptions;
  }

  html() {
    let buttons = '';
    Object.keys(this.pasteOptions).forEach((k) => {
      const o = this.pasteOptions[k];
      o.id = genId();
      buttons += `<button type="button" id="${o.id}" class="ptro-selector-btn ptro-color-control">`
        + `<div><i class="ptro-icon ptro-icon-paste_${o.internalName}"></i></div>`
        + `<div>${tr(`pasteOptions.${o.internalName}`)}</div>`
      + '</button>';
    });
    return '<div class="ptro-paster-select-wrapper" hidden><div class="ptro-paster-select ptro-v-middle">'
      + '<div class="ptro-in ptro-v-middle-in">'
      + `<div class="ptro-paste-label">${tr('pasteOptions.how_to_paste')}</div>${
        buttons}</div></div></div>`;
  }
}
export function setActivePasteOptions(a) {
  return Inserter.get().activeOptions(a);
}
