import React, { useState, useEffect } from 'react';
import { useAnnouncementsList } from '../GameData.js';

const AnnouncementSection = () => {
  const announcements = useAnnouncementsList();
  const [readState, setReadState] = useState({});

  const markRead = (key) => {
    const newObj = Object.assign({}, readState);
    newObj[key] = true;
    setReadState(newObj);
    window.localStorage.setItem("announcementRead", JSON.stringify(newObj));
  };
  
  useEffect(() => {
    setReadState(JSON.parse(window.localStorage.getItem("announcementRead")) || {});
  }, []);

  return <div className="announcementSection">
    { announcements.filter(a => readState !== null && !readState[a.key]).map(a => 
      <div className="announcementSet rounded" key={a.key} onClick={() => markRead(a.key)} >{a.value}</div>
    ) }
  </div>;
};
  
export default AnnouncementSection;