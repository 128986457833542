import React, { useEffect, useRef } from 'react';
import { Howl } from 'howler';
import { useGameData, usePresentation } from '../GameData.js';
import BookListEntry from './BookListEntry.js';

const BookList = () => {
  const { books, users, user, assignBook } = useGameData();
  const presentation = usePresentation();
  const prevUnfinishedCount = useRef(0);

  useEffect(() => {
    const unfinishedBookCount = books.filter(b => !b.finishedTime).length;
    if(prevUnfinishedCount.current !== unfinishedBookCount && unfinishedBookCount === 0) {
        new Howl({ src: ['audio/complete.mp3'], volume: 0.5 }).play();
    }
    prevUnfinishedCount.current = unfinishedBookCount;
  }, [books]);

  const props = { user, users, presentation, assignBook };

  return <ul>
    {books.map(b => <BookListEntry { ...props } book={ b } key={ b.key } />)}
  </ul>;
};
  
export default BookList;