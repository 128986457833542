import React, { useContext } from 'react';
import { usePledge, useUsersList, useIsModerator, useIsCreator } from '../GameData.js';
import { LocalGameContext } from '../LocalGameContext.js';

const UserList = () => {
  const pledgeAmount = usePledge();
  const users = useUsersList();
  const isModerator = useIsModerator();
  const isCreator = useIsCreator();
  const [user] = useContext(LocalGameContext);

  const playerUsers = users.filter(u => u.accessLevel >= 40);
  const observerUsers = users.filter(u => u.accessLevel < 40 && u.accessLevel >= 20 && u.isActive);

  const sortUsers = (a, b) => {
    if(a.isActive && !b.isActive) return -1;
    if(!a.isActive && b.isActive) return 1;
    return a.displayName.localeCompare(b.displayName);
  };

  const playerMax = (pledgeAmount < 100) ? 8 : 14;

  const setAllPlayers = () => {
    const observers = observerUsers.slice(0, playerMax - playerUsers.length);
    for(let i = 0; i < observers.length; i++) {
      setAccessLevel(observers[i], 40);
    }
  };

  const setAccessLevel = (u, accessLevel) => {
    if(u.key === user.uid && isCreator && accessLevel > 20) accessLevel = 100;
    else if(accessLevel >= 40 && u.accessLevel < 40) {
      // Adding a player
      /*if(playerUsers.length >= 14 || (pledgeAmount < 100 && playerUsers.length >= 6)) {
        alert("Due to very high usage, the player limit has been temporarily lowered. This room has reached the player limit. Room creators who are patrons have a higher player limit.\n\nDevido a utilização elevada, o limite do jogadores foi temporariamente reduzido. Esta sala atingiu o limite de jogadores. Os criadores de salas que são apoiadores no Patreon têm um limite maior de jogadores.");
        return;
      }*/
      if(playerUsers.length >= playerMax) {
        alert("This room has reached the player limit. Room creators who are patrons have a higher player limit.\n\nEsta sala atingiu o limite de jogadores. Os criadores de salas que são apoiadores no Patreon têm um limite maior de jogadores.");
        return;
      }
    }
    u.ref.update({ accessLevel });
    if(accessLevel === 0) {
      u.ref.update({ isActive: false });
    }
    return;
  };

  const createItem = u => {
    var btn = null;
    
    if(u.accessLevel !== 100) {
      if(isCreator || isModerator) {
        if(u.accessLevel < 40) btn = <button className="modButton" title="Make player" onClick={() => setAccessLevel(u,40)}>🔼</button>;
      }
      if(isCreator || isModerator || u.key === user.uid) {
        if(u.accessLevel !== 20) btn = <button className="modButton" title="Make waitlisted" onClick={() => setAccessLevel(u,20)}>❌</button>;
      }
    }

    let className = u.isActive ? 'userActive' : 'userInactive';
    if(u.key === user.uid) className = className + ' userSelf';
    
    return (
      <li key={ u.key } className={ className } style={ u.color && u.isActive ? { color: u.color } : {}}>
        <span className="username">{ u.displayName }</span>
        { u.tag && <img className="tag" alt="tag" src={u.tag} /> }
        { btn }
        <span className={"permission" + u.accessLevel} />
      </li>
    );
  };



  return (<div>
    <h4>Players</h4>
    <ul className='activePlayers'>{ playerUsers.sort(sortUsers).map(u => createItem(u)) }</ul>
    <h4>Waitlist</h4>
    <ul className='observerUsers'>{ observerUsers.sort(sortUsers).map(u => createItem(u)) }</ul>
    { ((isCreator || isModerator) && (observerUsers && observerUsers.length > 0)) ? 
      <button disabled={playerUsers && playerUsers.length >= playerMax} onClick={() => setAllPlayers()}>Make all players</button>
      : null }
  </div>
  );
};
  
export default UserList;