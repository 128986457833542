import { useContext } from 'react';
import { LocalGameContext } from './LocalGameContext.js';
import { useFirebaseApp, useDatabaseObject, useDatabaseList } from 'reactfire';
import firebase from "firebase/app";
import { customAlphabet } from 'nanoid';
import 'firebase/database';
import * as tracery from 'tracery-grammar';
import grammarObj from './grammary/phraseGrammar.json';

export const imagePrefix = "https://www.brokenpicturephone.com/moves/";
export const imageEndpoint = "https://www.brokenpicturephone.com/moveputv2.php";
export const TIMESTAMP = firebase.database.ServerValue.TIMESTAMP;
export const imageUrl = (moveId) => imagePrefix + moveId + '.png';

const objTransform = (obj) => {
  if(!obj || !obj.snapshot) return {};
  let val = obj.snapshot.val() || {};
  if(!(typeof val === "object")) {
    val = { value: val };
  }
  if(obj.snapshot.key) val.key = obj.snapshot.key;
  if(obj.snapshot.ref) val.ref = obj.snapshot.ref;
  return val;
}

const listTransform = (list) => {
  return (list || []).map(val => objTransform(val));
}

const nameLookup = (users) => (uid) => {
  if(uid === "c") return "Computer";
  if(!users || !users[uid] || !users[uid].displayName) return "Unknown user";
  return users[uid].displayName;
}

export const KEYS = {
  finishedTime: 'f',
  rooms: 'r',
  specialMode: 's',
  announcements: 'a',
  refreshes: 'f',
  patreon: 'p',
  books: 'b',
  users: 'u',
  moves: 'm',
  presentation: 'p',
  assignedTime: 'a',
  moveType: 't',
  previousMove: 'p',
  user: 'u',
  options: 'o',
  nextType: 'n',
  firstType: 'f',
  textLength: 'l',
  timerForImage: 'ti',
  timerForText: 'tt',
  ready: 'r',
  base: 'base',
  preassigned: 'pa',
}

export const newPageId = customAlphabet('_0123456789ABCDEFGHIJKLMNOPQRSTUVWYZabcdefghijklmnopqrstuvwyz', 9);

export const sortEntries = (a, b) => {
  if(a[1][KEYS.assignedTime] !== b[1][KEYS.assignedTime]) return a[1][KEYS.assignedTime] - b[1][KEYS.assignedTime];
  if(a[1][KEYS.user] === "c") return -1;
  if(b[1][KEYS.user] === "c") return 1;
  return a.key < b.key;
};

export const sortPages = (a, b) => {
  if(a[KEYS.assignedTime] !== b[KEYS.assignedTime]) return a[KEYS.assignedTime] - b[KEYS.assignedTime];
  if(a[KEYS.user] === "c") return -1;
  if(b[KEYS.user] === "c") return 1;
  return a.key < b.key;
};

export const usePresentation = () => {
  const [, context] = useContext(LocalGameContext);
  const firebaseApp = useFirebaseApp();
  const ref = firebaseApp.database().ref(KEYS.rooms).child(context.roomName).child(KEYS.presentation);
  const { data } = useDatabaseObject(ref);
  return objTransform(data);
}

export const useBook = (id) => {
  const [, context] = useContext(LocalGameContext);
  const firebaseApp = useFirebaseApp();
  const ref = firebaseApp.database().ref(KEYS.rooms).child(context.roomName).child(KEYS.books).child(id);
  const { data } = useDatabaseObject(ref);
  return objTransform(data);
}

export const useBooksList = () => {
  const [, context] = useContext(LocalGameContext);
  const firebaseApp = useFirebaseApp();
  const ref = firebaseApp.database().ref(KEYS.rooms).child(context.roomName).child(KEYS.books);
  const { data: list } = useDatabaseList(ref);
  return listTransform(list);
}

export const useBooksRef = () => {
  const [, context] = useContext(LocalGameContext);
  const firebaseApp = useFirebaseApp();
  return firebaseApp.database().ref(KEYS.rooms).child(context.roomName).child(KEYS.books);
}

export const useRefreshRef = () => {
  const [, context] = useContext(LocalGameContext);
  const firebaseApp = useFirebaseApp();
  return firebaseApp.database().ref(KEYS.refreshes).child(context.roomName);
}

export const useUsers = () => {
  const [, context] = useContext(LocalGameContext);
  const firebaseApp = useFirebaseApp();
  const ref = firebaseApp.database().ref(KEYS.rooms).child(context.roomName).child(KEYS.users);
  const { data } = useDatabaseObject(ref);
  const users = objTransform(data);
  users.name = nameLookup(users);
  return users;
}

export const useUserSelf = () => {
  const [user, context] = useContext(LocalGameContext);
  const firebaseApp = useFirebaseApp();
  const ref = firebaseApp.database().ref(KEYS.rooms).child(context.roomName).child(KEYS.users).child(user.uid);
  const { data } = useDatabaseObject(ref);
  const userObj = objTransform(data);
  return userObj;
}

export const useUsersList = () => {
  const [, context] = useContext(LocalGameContext);
  const firebaseApp = useFirebaseApp();
  const ref = firebaseApp.database().ref(KEYS.rooms).child(context.roomName).child(KEYS.users);
  const { data: list } = useDatabaseList(ref);
  return listTransform(list);
}

export const useAnnouncementsList = () => {
  const firebaseApp = useFirebaseApp();
  const ref = firebaseApp.database().ref(KEYS.announcements);
  const { data: list } = useDatabaseList(ref);
  return listTransform(list);
}

export const useIsPlayer = () => {
  const [user, context] = useContext(LocalGameContext);
  const firebaseApp = useFirebaseApp();
  const ref = firebaseApp.database().ref(KEYS.rooms).child(context.roomName).child(KEYS.users).child(user.uid).child("accessLevel");
  const { data } = useDatabaseObject(ref);
  return data && data.snapshot && data.snapshot.val() >= 40;
}

export const useIsModerator = () => {
  const [user, context] = useContext(LocalGameContext);
  const firebaseApp = useFirebaseApp();
  const ref = firebaseApp.database().ref(KEYS.rooms).child(context.roomName).child(KEYS.users).child(user.uid).child("accessLevel");
  const { data } = useDatabaseObject(ref);
  return data && data.snapshot && data.snapshot.val() >= 80;
}

export const useIsCreator = () => {
  const [user, context] = useContext(LocalGameContext);
  const firebaseApp = useFirebaseApp();
  const ref = firebaseApp.database().ref(KEYS.rooms).child(context.roomName).child("creator");
  const { data } = useDatabaseObject(ref);
  return data && data.snapshot && (data.snapshot.val() === user.uid);
}

export const useOptions = () => {
  const [, context] = useContext(LocalGameContext);
  const firebaseApp = useFirebaseApp();
  const ref = firebaseApp.database().ref(KEYS.rooms).child(context.roomName).child(KEYS.options);
  const { data } = useDatabaseObject(ref);
  const optionsObj = objTransform(data);
  return optionsObj;
}

export const usePledge = () => {
  const [user, ] = useContext(LocalGameContext);
  const firebaseApp = useFirebaseApp();
  const ref = firebaseApp.database().ref(KEYS.patreon).child(user.uid);
  const { data } = useDatabaseObject(ref);
  return (data && data.snapshot && data.snapshot.val()) || 0;
}

// One source for the majority of the game data, plus the assign book functionality.
// Only for use in components that need all of the data.
// BooksList, Users, user, assignBook, context
export const useGameData = () => {
  const [user, context] = useContext(LocalGameContext);
  const firebaseApp = useFirebaseApp();
  const ref = firebaseApp.database().ref(KEYS.rooms).child(context.roomName);

  const refUsers = ref.child(KEYS.users);
  const { data } = useDatabaseObject(refUsers);
  const users = objTransform(data);
  users.name = nameLookup(users);

  const refOptions = ref.child(KEYS.options);
  const { data: optionsData } = useDatabaseObject(refOptions);
  const options = objTransform(optionsData);

  const refBooks = ref.child(KEYS.books);
  const { data: list } = useDatabaseList(refBooks);
  const books = listTransform(list);

  const fixedMoveCount = options?.[KEYS.moves] !== undefined ? parseInt(options?.[KEYS.moves]) : false;

  const nextPage = (book, isReassign = false) => {
    return Object.values(book[KEYS.moves]).length + (isReassign ? 0 : 1);
  }
  const preassignedPlayer = options?.[KEYS.preassigned] ? Object.keys(options?.[KEYS.preassigned])[0] : false;
  const preassignedMoves = preassignedPlayer ? options[KEYS.preassigned][preassignedPlayer].split(",").map(t => parseInt(t)) : [];

  const canBeSentToUsers = (book, isReassign) => {
    let excludedUsers = [];
    if(fixedMoveCount === false) {
      // If this is a one-page-per-player book, exclude everyone who has already made a page
      // List of excluded users includes the current user, if an active page is being reassigned; this is intentional
      excludedUsers = Object.values(book[KEYS.moves]).map(m => m[KEYS.user]);
    }
    if(preassignedPlayer) {
      const pageNumber = nextPage(book, isReassign);
      if(preassignedMoves.includes(pageNumber)) {
        return [preassignedPlayer];
      }
      else {
        excludedUsers.push(preassignedPlayer);
      }
    }
    const validUsers = Object.entries(users).filter(u => u[1].isActive && u[1].accessLevel >= 40).map(u => u[0]).filter(uid => !excludedUsers.includes(uid));
    return validUsers;
  }

  const isOnLastPage = (book) => {
    if(fixedMoveCount !== false) {
      if(fixedMoveCount === 0) return false;
      return Object.values(book[KEYS.moves]).length >= fixedMoveCount;
    }
    return canBeSentToUsers(book).length === 0;
  }

  const assignBook = (book, options = {}) => {
    if(options.forceEnd || (isOnLastPage(book) && (fixedMoveCount === false || !options.isReassign))) {
      // The book is complete, finalize it
      const updateObj = {
        activePage: null,
        finishedTime: TIMESTAMP,
      };
      if(options.isReassign) {
        updateObj[`${KEYS.moves}/${book.activePage}`] = null;
      }
      book.ref.update(updateObj);
      return;
    }

    // Determining the move type
    const lastType = book[KEYS.moves][book.activePage][KEYS.moveType];
    let moveType = book[KEYS.nextType];
    if(moveType === "alternate") {
      moveType = lastType === "text" ? "image" : "text";
    }
    if(options.isReassign) {
      moveType = lastType;
    }

    // Rank users and choose one of them with the lowest number of outstanding pages
    const userRanks = {};
    let validUsers = canBeSentToUsers(book, options.isReassign);
    if(options.sendSelf) {
      validUsers = [ book[KEYS.moves][book.activePage][KEYS.user] ];
    }
    validUsers.forEach(uid => {
      let rank = 0;
      // Decrease rank by 5 for each page already made in this book (multi-page only)
      const doneInThisBook = Object.values(book[KEYS.moves]).filter(m => m[KEYS.user] === uid).length;
      rank -= (5 * doneInThisBook);

      // Decrease rank by 100 if activePage, or 50 if previous-activePage was this user (multi-page only, handles reassign)
      if(book[KEYS.moves][book.activePage][KEYS.user] === uid) {
        rank =- 100;
      }
      const previousMoveId = book[KEYS.moves][book.activePage][KEYS.previousMove];
      if(previousMoveId && book[KEYS.moves][previousMoveId][KEYS.user] === uid) {
        rank =- 50;
      }

      // Decrease rank by 2 for each page currently waiting
      const waitingPages = books.filter(b => b.activePage && b[KEYS.moves][b.activePage][KEYS.user] === uid).length;
      rank -= (2 * waitingPages);

      // Decrease rank by 1 for each page of the current type already done
      let doneOfThisType = 0;
      books.forEach(b => {
        const moveList = Object.values(b[KEYS.moves]);
        const thisBookDott = moveList.filter(m => m[KEYS.user] === uid && m[KEYS.moveType] === moveType).length;
        doneOfThisType += thisBookDott;
      });
      rank -= doneOfThisType;
      userRanks[uid] = rank;
    });
    const maxRank = Math.max(...Object.values(userRanks));
    const maxRankUsers = Object.keys(userRanks).filter(uid => userRanks[uid] === maxRank);
    const chosenUser = maxRankUsers[Math.floor(Math.random() * maxRankUsers.length)];

    // If reassign, change current book assignment to chosen user and return
    if(options.isReassign) {
      book.ref.child(KEYS.moves).child(book.activePage).update({
        [KEYS.user]: chosenUser,
        [KEYS.assignedTime]: TIMESTAMP,
      });
      return;
    }

    // If not reassign, assign a new page to the chosen user
    const newPagePush = {
      [KEYS.moveType]: moveType,
      [KEYS.user]: chosenUser,
      [KEYS.assignedTime]: TIMESTAMP,
      [KEYS.previousMove]: book.activePage,
    };
    const newPageKey = newPageId();
    book.ref.child(KEYS.moves).child(newPageKey).set(newPagePush);
    book.ref.update({ activePage: newPageKey });
  };

  return { users, options, books, user, assignBook, isOnLastPage, context };
}

export const useGameDeleterData = () => {
  const booksList = useBooksList();
  const booksRef = useBooksRef();
  const deleteGame = () => {
    if(window.confirm("This will permanently delete all existing books. Proceed?")) {
      finalDeleteGame();
    }
  };
  const finalDeleteGame = () => {
    const newObj = {};
    newObj[KEYS.presentation] = null;
    newObj[KEYS.options] = null;
    newObj[booksRef.key] = null;
    booksRef.parent.update(newObj);
  };
  return { deleteGame, booksList };
}

export const useBookCreatorData = () => {
  const booksRef = useBooksRef();
  const users = useUsersList();

  const createBookData = (settings) => {
    finalCreateBookData(settings);
  };
  const finalCreateBookData = (settings) => {
    booksRef.remove();
    const newBooksObj = {};
    const newOptionsObj = {};

    const newBookTemplate = {};
    newBookTemplate[KEYS.nextType] = settings.nextType;
    newOptionsObj[KEYS.nextType] = settings.nextType;
    newOptionsObj[KEYS.firstType] = settings.firstType;
    let calcFirstType = settings.firstType;

    if(settings.special === "tower") {
      newBookTemplate[KEYS.specialMode] = "tower";
      newOptionsObj[KEYS.specialMode] = "tower";
      newBookTemplate[KEYS.nextType] = "image";
      newOptionsObj[KEYS.nextType] = "image";
      calcFirstType = "image";
    }

    if(settings.special === "bigimage") {
      newBookTemplate[KEYS.specialMode] = "bigimage";
      newOptionsObj[KEYS.specialMode] = "bigimage";
    }

    if(settings.textLength) {
      newOptionsObj[KEYS.textLength] = settings.textLength;
    }

    if(settings.moveCount !== undefined) {
      newOptionsObj[KEYS.moves] = settings.moveCount;
    }

    if(settings.timerForImage) {
      newOptionsObj[KEYS.timerForImage] = settings.timerForImage;
    }

    if(settings.timerForText) {
      newOptionsObj[KEYS.timerForText] = settings.timerForText;
    }

    if(settings.preassignedPlayer) {
      newOptionsObj[KEYS.preassigned] = {};
      newOptionsObj[KEYS.preassigned][settings.preassignedPlayer] = Object.entries(settings.preassignedMoves).filter(e => {
        return e[1] === true}).map(e => e[0]).join(",");
    }

    let userList = users.filter(u => u.isActive && u.accessLevel >= 40).map(u => [Math.random(), u]).sort((a, b) => a[0]-b[0]).map(a => a[1]);
    if(settings.preassignedPlayer && settings.preassignedMoves["1"] !== true) {
      userList = userList.filter(u => u.key !== settings.preassignedPlayer);
    }

    let bookCount = userList.length;
    if(settings.bookCount && !isNaN(parseInt(settings.bookCount))) {
      bookCount = parseInt(settings.bookCount);
    }

    let startPhrases = [];
    // Assumes bookCount will always be less than #origin# length
    if(settings.generateStarter) {
      const grammar = tracery.createGrammar(grammarObj);
      grammar.addModifiers(tracery.baseEngModifiers); 
      const phraseOrigins = grammarObj["origin"].map(u => [Math.random(), u]).sort((a, b) => a[0]-b[0]).map(a => a[1]).slice(0, bookCount);
      startPhrases = phraseOrigins.map(o => grammar.flatten(o));
    }
    
    for(let i = 0; i < bookCount; i++) {
      const u = userList[i % userList.length];
      const newBookPush = Object.assign({
        startUser: u.key,
      }, newBookTemplate);
      const newBookKey = `b${i + 1}`;
      newBooksObj[newBookKey] = newBookPush;
      const finalFirstType = calcFirstType === 'alternate' ? ['text','image'][Math.floor(Math.random()*2)] : calcFirstType;
      const newPagePush = {
        [KEYS.moveType]: finalFirstType,
        [KEYS.user]: u.key,
        [KEYS.assignedTime]: TIMESTAMP,
      };
      if(settings.preassignedPlayer && settings.preassignedMoves["1"] === true) {
        newBookPush.startUser = settings.preassignedPlayer;
        newPagePush[KEYS.user] = settings.preassignedPlayer;
      }
      if(finalFirstType === 'image' && settings.generateStarter) {
        newPagePush[KEYS.base] = Math.floor(Math.random() * 58) + 1; // Number of possible starter images
      }
      const newPageKey = newPageId();
      newBooksObj[newBookKey][KEYS.moves] = {};
      newBooksObj[newBookKey][KEYS.moves][newPageKey] = newPagePush;
      newBooksObj[newBookKey].activePage = newPageKey;

      if(finalFirstType === 'text' && startPhrases && startPhrases.length > 0) {
        // Change the first page to computer-generated and make the next page
        const secondPagePush = {
          [KEYS.moveType]: settings.nextType === 'text' ? 'text' : 'image',
          [KEYS.user]: u.key,
          [KEYS.assignedTime]: TIMESTAMP,
          [KEYS.previousMove]: newPageKey,
        }
        // Reassign relevant values on first page
        newPagePush[KEYS.user] = "c";
        newPagePush[KEYS.finishedTime] = 1;
        newPagePush["text"] = startPhrases.pop();
        // Assign pages correctly
        const secondPageKey = newPageId();
        newBooksObj[newBookKey][KEYS.moves][secondPageKey] = secondPagePush;
        newBooksObj[newBookKey].activePage = secondPageKey;
      }
    };
    const newObj = {};
    newObj[KEYS.presentation] = null;
    newObj[KEYS.options] = newOptionsObj;
    newObj[booksRef.key] = newBooksObj;
    booksRef.parent.update(newObj).then(() => {
      booksRef.parent.child(KEYS.options).child(KEYS.ready).set(1);
    });
  };
  return { createBookData, users };
}