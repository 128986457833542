import React, { useState } from 'react';
import { /*useTransition,*/ animated } from 'react-spring';

// Custom components you intend to map through this must use forwardRef
export const useHeightTransition = (items, keyFn) => {
    const [refMap] = useState(() => new Map());

   /*  
    const height = (el) => {
        console.log("height:", el, el ? el.offsetHeight || 0 : -1)
        return el ? el.offsetHeight || 0 : 0;
    }
          
    const heights = {
        from: { opacity: 0, height: 0 },
        enter: item => async next => {
            console.log(`  Entering:`, item.key, refMap.has(item.key));
            await next({ opacity: 1, height: height(refMap.get(keyFn(item))) })
        },
        leave: { opacity: 0, height: 0 },
    }
    const transitions = useTransition(items, keyFn, heights);

    // Optional method to reduce boilerplate and need for refMap in components that use this transition
    transitions.mapItemNeedsFixed = (Component, additionalProps = {}) => 
        transitions.map(({ item, props, key }) =>
            <animated.div key={ key } style={{ overflow: "hidden", ...props }}>
                <Component { ...additionalProps } item={ item } ref={ref => ref && refMap.set(keyFn(item), ref) }/>
            </animated.div>
        );
    ;*/

    const transitions = {}
    transitions.mapItem = (Component, additionalProps = {}) => 
        items.map(item =>
            <animated.div key={ keyFn(item) }>
                <Component { ...additionalProps } item={ item } ref={ref => ref && refMap.set(keyFn(item), ref) }/>
            </animated.div>
        );
    ;

    return [transitions, refMap];
}