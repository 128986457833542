import { useEffect } from 'react';
import { useGameData, sortPages, KEYS } from '../GameData.js';
import ActivePage from './ActivePage.js';

const ActivePages = () => {
  const { books, options, users, user, assignBook, isOnLastPage } = useGameData();
  
  const handleFinalSubmit = (page, fields = {}, options = {}) => {
    page.ref.update({
      ...fields,
      [KEYS.finishedTime]: 1,
    }).then(() => {
      const book = books.find(b => b.key === page.ref.parent.parent.key);
      let assignOptions = {};
      if(options.nextAction === 'forceEnd') assignOptions.forceEnd = true;
      if(options.nextAction === 'sendSelf') assignOptions.sendSelf = true;
      assignBook(book, assignOptions);
    }).catch(error => {
      console.warn('Error - ' + error.message);
      console.log(page.ref.toString());
      console.log(fields);
      console.log(user);
      console.log(error);
    });
  };

  const pagesToDisplay = [];
  let completedBookCount = 0;
  let myUndoneBookCount = 0;
  let totalCompletedPageCount = 0;
  books.forEach(book => {
    totalCompletedPageCount += Object.values(book[KEYS.moves]).filter(b => b[KEYS.finishedTime]).length;
    if(!users || !users[user.uid] || users[user.uid].accessLevel < 40) return;
    if(book.finishedTime || !book[KEYS.moves] || !book.activePage) {
      completedBookCount += 1;
      return;
    }
    if(Object.values(book[KEYS.moves]).filter(b => b[KEYS.user] === user.uid).length === 0) {
      myUndoneBookCount += 1;
    }

    const activePage = book[KEYS.moves][book.activePage];
    if(!activePage || activePage[KEYS.user] !== user.uid) return;
    activePage.key = book.activePage;
    activePage.isLast = isOnLastPage(book);
    activePage.ref = book.ref.child(KEYS.moves).child(book.activePage);
    if(activePage[KEYS.previousMove]) {
      activePage.previousPage = book[KEYS.moves][activePage[KEYS.previousMove]];
      activePage.previousPage.key = activePage[KEYS.previousMove];
    }
    if(book[KEYS.specialMode] === "tower") {
      activePage.isTower = true;
    }
    if(book[KEYS.specialMode] === "bigimage") {
      activePage.isBigImage = true;
    }
    pagesToDisplay.push(activePage);
  });

  useEffect(() => {
    document.title = pagesToDisplay.length ? `(${pagesToDisplay.length}) - Broken Picturephone` : `Broken Picturephone`;
  });

  pagesToDisplay.sort(sortPages);
  const props = { handleFinalSubmit, options };

  let retVal = null;
  if(books.length === 0) {
    retVal = null;
  }
  else if(completedBookCount === books.length) {
    retVal = <div className="waitingSet rounded">Waiting for the room creator to present a book...</div>
  }
  else if(pagesToDisplay.length === 0) {
    let waitMsg;
    if(options?.[KEYS.moves]) {
      const todoPageCount = (parseInt(options?.[KEYS.moves]) * books.length) - totalCompletedPageCount;
      waitMsg = `There ${todoPageCount === 1 ? 'is' : 'are'} ${todoPageCount} page${todoPageCount === 1 ? '' : 's'} to be completed before all books are finished.`;
    }
    else {
      waitMsg = `There ${myUndoneBookCount === 1 ? 'is' : 'are'} ${myUndoneBookCount} book${myUndoneBookCount === 1 ? '' : 's'} you have not created a page in yet.`;
    }
    retVal = <div className="waitingSet rounded">Waiting for pages to be completed. {waitMsg}</div>
  }
  else {
    let waitMsg = `${pagesToDisplay.length - 1} additional page${pagesToDisplay.length === 2 ? '' : 's'} waiting for you.`;
    retVal = <>
      <ActivePage item={pagesToDisplay[0]} {...props} key={pagesToDisplay[0].key} />
      { (pagesToDisplay.length > 1) && <div className="waitingSet rounded">{waitMsg}</div> }
    </>;
  }
  return retVal;

};
  
export default ActivePages;