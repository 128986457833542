import React, { useEffect, useContext } from 'react';
import './UserSection.css';
import { useIsModerator } from '../GameData.js';
import UserList from './UserList.js';
import { useRefreshRef, TIMESTAMP } from '../GameData';
import { LocalGameContext } from '../LocalGameContext.js';


const UserSection = () => {
  const [, context] = useContext(LocalGameContext);
  const isModerator = useIsModerator();
  const refreshRef = useRefreshRef();
  
  useEffect(() => {
    if(!isModerator) return;
    refreshRef.set(TIMESTAMP);
    let timer = setInterval(() => {
      refreshRef.set(TIMESTAMP);
    }, 1000 * 60 * 5); // 5 minutes
    return () => {
      clearInterval(timer);
    }
  }, [refreshRef, isModerator, context.roomName]);

  return (
    <div className="userSection rounded">
    <h3>User List</h3>
    <UserList />
    </div>
  );
};
  
export default UserSection;