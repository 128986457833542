export default class PrimitiveTool {
  constructor(main) {
    this.ctx = main.ctx;
    this.el = main.toolContainer;
    this.main = main;
    this.backCanvas = null;
    this.helperCanvas = null;
    this.helperCtx = null;
    this.canvas = main.canvas;
  }

  activate(type) {
    this.type = type;
    this.state = {};
    if (type === 'line' || type === 'brush' || type === 'eraser' || type === 'arrow') {
      this.ctx.lineJoin = 'round';
    } else {
      this.ctx.lineJoin = 'miter';
    }
  }

  setLineWidth(width) {
    this.lineWidth = width;
  }

  setArrowLength(length) {
    this.arrowLength = length;
  }

  setEraserWidth(width) {
    this.eraserWidth = width;
  }

  handleMouseDown(event) {
    this.activate(this.type);
    const mainClass = event.target.classList[0];
    this.ctx.lineWidth = this.lineWidth;
    this.ctx.strokeStyle = this.main.colorWidgetState.line.alphaColor;
    this.ctx.fillStyle = this.main.colorWidgetState.fill.alphaColor;
    const scale = this.main.getScale();
    this.ctx.lineCap = 'round';
    if (mainClass === 'ptro-crp-el' || mainClass === 'ptro-zoomer') {
      this.backCanvas = document.createElement('canvas');
      this.backCanvas.width = this.canvas.width;
      this.backCanvas.height = this.canvas.height;
      this.backCanvas.ctx = this.backCanvas.getContext('2d');
      this.backCanvas.ctx.drawImage(this.canvas, 0, 0);
      if (this.type === 'brush' || this.type === 'eraser') {
        this.state.cornerMarked = true;
        const cord = [
          (event.clientX - this.main.elLeft()) + this.main.scroller.scrollLeft,
          (event.clientY - this.main.elTop()) + this.main.scroller.scrollTop,
        ];
        const noPressure = event.pressure === undefined || event.pressure < 0.001;
        const cur = {
          x: cord[0] * scale,
          y: cord[1] * scale,
          percent: noPressure ? 1 : (event.pressure * 2),
        };

        this.drawBrushPath(cur);
      } else {
        this.state.cornerMarked = true;
        this.centerCord = [
          (event.clientX - this.main.elLeft()) + this.main.scroller.scrollLeft,
          (event.clientY - this.main.elTop()) + this.main.scroller.scrollTop,
        ];
        this.centerCord = [this.centerCord[0] * scale, this.centerCord[1] * scale];
      }
    }
  }

  drawBrushPath(p) {
    const isEraser = this.type === 'eraser';
    let baseLineWidth = this.lineWidth;
    let lineFill = this.main.colorWidgetState.line.alphaColor;
    if (isEraser) {
      lineFill = 'white';
      baseLineWidth = this.eraserWidth;
    }

    if (!this.helperCanvas) {
      this.helperCanvas = document.createElement('canvas');
      this.helperCanvas.width = this.main.size.w;
      this.helperCanvas.height = this.main.size.h;
      this.helperCtx = this.helperCanvas.getContext('2d');
      this.helperCtx.lineCap = 'round';
      this.helperCtx.fillStyle = 'black';
      this.helperCtx.strokeStyle = 'black';

      this.helperCanvas2 = document.createElement('canvas');
      this.helperCanvas2.width = this.main.size.w;
      this.helperCanvas2.height = this.main.size.h;
      this.helperCtx2 = this.helperCanvas2.getContext('2d');
      this.helperCtx2.fillStyle = lineFill;

      // Draw 0th point as circle
      this.helperCtx.beginPath();
      this.helperCtx.lineWidth = 0;
      this.helperCtx.arc(
        p.x, p.y,
        (baseLineWidth / 2) * p.percent,
        0, 2 * Math.PI,
      );
      this.helperCtx.fill();
    } else {
      this.helperCtx.beginPath();
      this.helperCtx.moveTo(this.lastP.x, this.lastP.y);
      this.helperCtx.lineWidth = baseLineWidth * p.percent;
      this.helperCtx.lineTo(p.x, p.y);
      this.helperCtx.stroke();
    }

    this.helperCtx2.drawImage(this.helperCanvas, 0, 0);

    this.helperCtx2.globalCompositeOperation = 'source-in';
    this.helperCtx2.fillRect(0, 0, this.main.size.w, this.main.size.h);
    this.helperCtx2.globalCompositeOperation = 'source-over';
    this.ctx.drawImage(this.helperCanvas2, 0, 0);

    this.lastP = p;
  }

  handleMouseMove(event) {
    if (this.state.cornerMarked) {
      this.ctx.drawImage(this.backCanvas, 0, 0);
      this.curCord = [
        (event.clientX - this.main.elLeft()) + this.main.scroller.scrollLeft,
        (event.clientY - this.main.elTop()) + this.main.scroller.scrollTop,
      ];
      const scale = this.main.getScale();
      this.curCord = [this.curCord[0] * scale, this.curCord[1] * scale];

      if (this.type === 'brush' || this.type === 'eraser') {
        const noPressure = event.pressure === undefined || event.pressure < 0.001;
        const cur = {
          x: this.curCord[0],
          y: this.curCord[1],
          percent: noPressure ? 1 : (event.pressure * 2),
        };
        this.drawBrushPath(cur);
      } else if (this.type === 'line') {
        if (event.ctrlKey || event.shiftKey) {
          const deg = (Math.atan(
            -(this.curCord[1] - this.centerCord[1]) / (this.curCord[0] - this.centerCord[0]),
          ) * 180) / Math.PI;
          if (Math.abs(deg) < 45.0 / 2) {
            this.curCord[1] = this.centerCord[1];
          } else if (Math.abs(deg) > 45.0 + (45.0 / 2)) {
            this.curCord[0] = this.centerCord[0];
          } else {
            const base = (Math.abs(this.curCord[0] - this.centerCord[0])
              - Math.abs(this.centerCord[1] - this.curCord[1])) / 2;

            this.curCord[0] -= base * (this.centerCord[0] < this.curCord[0] ? 1 : -1);
            this.curCord[1] -= base * (this.centerCord[1] > this.curCord[1] ? 1 : -1);
          }
        }
        this.ctx.beginPath();
        this.ctx.moveTo(this.centerCord[0], this.centerCord[1]);
        this.ctx.lineTo(this.curCord[0], this.curCord[1]);
        this.ctx.closePath();
        this.ctx.stroke();
      } else if (this.type === 'arrow') {
        let deg = (Math.atan(
          -(this.curCord[1] - this.centerCord[1]) / (this.curCord[0] - this.centerCord[0]),
        ) * 180) / Math.PI;
        if (event.ctrlKey || event.shiftKey) {
          if (Math.abs(deg) < 45.0 / 2) {
            this.curCord[1] = this.centerCord[1];
          } else if (Math.abs(deg) > 45.0 + (45.0 / 2)) {
            this.curCord[0] = this.centerCord[0];
          } else {
            const base = (Math.abs(this.curCord[0] - this.centerCord[0])
              - Math.abs(this.centerCord[1] - this.curCord[1])) / 2;

            this.curCord[0] -= base * (this.centerCord[0] < this.curCord[0] ? 1 : -1);
            this.curCord[1] -= base * (this.centerCord[1] > this.curCord[1] ? 1 : -1);
          }
        }
        if (this.curCord[0] < this.centerCord[0]) {
          deg = (180 + deg);
        }
        const { arrowLength } = this;
        const arrowAngle = this.main.params.defaultArrowAngle;
        const arrow = [
          [
            this.curCord[0] - (Math.cos((arrowAngle - deg) / (180 / Math.PI)) * arrowLength),
            this.curCord[1] - (Math.sin((arrowAngle - deg) / (180 / Math.PI)) * arrowLength),
          ],
          [
            this.curCord[0] - (Math.cos((-arrowAngle - deg) / (180 / Math.PI)) * arrowLength),
            this.curCord[1] - (Math.sin((-arrowAngle - deg) / (180 / Math.PI)) * arrowLength),
          ],
        ];
        this.ctx.beginPath();
        this.ctx.moveTo(this.centerCord[0], this.centerCord[1]);
        this.ctx.lineTo(this.curCord[0], this.curCord[1]);
        this.ctx.moveTo(this.curCord[0], this.curCord[1]);
        this.ctx.lineTo(arrow[0][0], arrow[0][1]);
        this.ctx.moveTo(this.curCord[0], this.curCord[1]);
        this.ctx.lineTo(arrow[1][0], arrow[1][1]);
        this.ctx.closePath();
        this.ctx.stroke();
      } else if (this.type === 'rect') {
        this.ctx.beginPath();

        const tl = [
          this.centerCord[0],
          this.centerCord[1]];

        let w = this.curCord[0] - this.centerCord[0];
        let h = this.curCord[1] - this.centerCord[1];

        if (event.ctrlKey || event.shiftKey) {
          const min = Math.min(Math.abs(w), Math.abs(h));
          w = min * Math.sign(w);
          h = min * Math.sign(h);
        }
        const halfLW = Math.floor(this.lineWidth / 2);
        const oddCorrecter = this.lineWidth % 2;
        this.ctx.rect(
          tl[0] + halfLW, tl[1] + halfLW,
          (w - this.lineWidth) + oddCorrecter, (h - this.lineWidth) + oddCorrecter,
        );
        this.ctx.fill();
        this.ctx.strokeRect(tl[0], tl[1], w, h);
        this.ctx.closePath();
      } else if (this.type === 'ellipse') {
        this.ctx.beginPath();
        const x1 = this.centerCord[0];
        const y1 = this.centerCord[1];
        let w = this.curCord[0] - x1;
        let h = this.curCord[1] - y1;

        if (event.ctrlKey || event.shiftKey) {
          const min = Math.min(Math.abs(w), Math.abs(h));
          w = min * Math.sign(w);
          h = min * Math.sign(h);
        }

        const rX = Math.abs(w);
        const rY = Math.abs(h);

        const tlX = Math.min(x1, x1 + w);
        const tlY = Math.min(y1, y1 + h);

        this.ctx.save();
        let xScale = 1;
        let yScale = 1;
        let radius;
        const hR = rX / 2;
        const vR = rY / 2;
        if (rX > rY) {
          yScale = rX / rY;
          radius = hR;
        } else {
          xScale = rY / rX;
          radius = vR;
        }
        this.ctx.scale(1 / xScale, 1 / yScale);
        this.ctx.arc(
          (tlX + hR) * xScale,
          (tlY + vR) * yScale,
          radius, 0, 2 * Math.PI,
        );
        this.ctx.restore();
        this.ctx.fill();
        this.ctx.stroke();

        this.ctx.beginPath();
      }
    }
  }

  handleMouseUp() {
    if (this.state.cornerMarked) {
      this.state.cornerMarked = false;
      this.helperCanvas = null;
      this.helperCtx = null;
      this.main.worklog.captureState();
    }
  }

  setPixelSize(size) {
    this.pixelSize = size;
  }
}
