import React, { useState, useEffect } from 'react';

const PageTimer = ({ id, seconds, onExpire }) => {

  const [remaining, setRemaining] = useState(1);
  
  useEffect(() => {
    if(remaining < 0) {
      setRemaining(0);
      onExpire();
    }
  }, [remaining, onExpire]);

  useEffect(() => {
    let localStorageKey = `timer-${id}`;
    if(window.localStorage.getItem(localStorageKey)) {
      const oldExpiryTime = parseInt(window.localStorage.getItem(localStorageKey));
      setRemaining(Math.max(10, Math.floor((oldExpiryTime - Date.now()) / 1000)));
    }
    else {
      let newExpiryTime = Date.now() + (seconds * 1000) + 500;
      window.localStorage.setItem(localStorageKey, newExpiryTime);
      setRemaining(seconds);
    }

    const token = setInterval(() => {
      setRemaining(n => n - 1);
    }, 1000);
    return () => {
      clearInterval(token);
    }
  }, [id, seconds]);

  const remainingD = Math.max(remaining, 0);

  return (
    <div className={`pageTimer ${remainingD < 45 ? 'timerSoon' : ''} ${remainingD < 10 ? 'timerImminent' : ''}`}>
      ⏲ {Math.floor(remainingD / 60)}:{(remainingD % 60).toString().padStart(2, '0')}
    </div>
  );
};
  
export default React.memo(PageTimer);