import React, { useState } from 'react';
import PageTimer from './PageTimer.js';
import TextareaAutosize from 'react-textarea-autosize';

const PageEntryText = ({ item, handleFinalSubmit, options, nextAction }) => {
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [text, setText] = useState("");

  const maxTextLength = (options && parseInt(options["l"])) || 69;
  const textTimer = (options && parseInt(options["tt"])) || null;

  const handleTextChange = (e) => {
    const newText = e.target.value.substring(0, maxTextLength);
    setText(newText);
    setSubmitDisabled(newText.length === 0);
  };
  
  const handleSubmit = (e) => {
    setSubmitDisabled(true);
    handleFinalSubmit(item, { text: text || "(N/A)" }, { nextAction });
  };

  const charactersRemaining = maxTextLength - text.length;

  return (<>
    <TextareaAutosize className="textInput" autoComplete="off" type="text" name={`writeEntry${item.key}`} id={`writeEntry${item.key}`} 
      placeholder="Short description" value={text} onChange={handleTextChange} />
    <span className="charactersRemaining">{charactersRemaining < 25 ? charactersRemaining : ''}</span>
    <button onClick={handleSubmit} disabled={submitDisabled}>Submit</button>
    {textTimer && <PageTimer id={item.key} seconds={textTimer} onExpire={handleSubmit} />}
  </>);
};

export default React.memo(PageEntryText);