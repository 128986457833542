import React from 'react';
import { useGameDeleterData, useIsModerator } from '../GameData';

const NewGameButton = () => {
  const { deleteGame, booksList } = useGameDeleterData();
  const isModerator = useIsModerator();
  if(!isModerator) return null;
  return (
    <button onClick={ deleteGame } disabled={ !booksList || booksList.length === 0 }>New Game</button>
  );
};

export default NewGameButton;