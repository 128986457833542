import React from 'react';
import './PresentationSection.css';
import { usePresentation, useIsModerator, useOptions, KEYS } from '../GameData.js';
import AnnouncementSection from './AnnouncementSection.js';
import PresentedBook from './PresentedBook.js';
import PresentationImageGrid from './PresentationImageGrid.js';
import ActivePages from './ActivePages.js';
import BookCreator from './BookCreator.js';


const PresentationSection = () => {
  const presentation = usePresentation();
  const isModerator = useIsModerator();
  const options = useOptions();

  const optionsSet = options && options[KEYS.nextType] && options[KEYS.ready];

  return (
    <div className="moveAndPresentationSection">
      <AnnouncementSection />
      { !optionsSet && (isModerator ? <BookCreator /> : <div className="waitingSet rounded">Waiting for the room creator to create books...</div>) }
      { presentation && presentation.imageGrid && <PresentationImageGrid /> }
      { presentation && presentation.book && <PresentedBook presentation={presentation} /> }
      <div className="moveSection">
        { optionsSet && (!presentation || !(presentation.imageGrid || presentation.book)) && <ActivePages /> }
      </div>
    </div>
  );
};
  
export default PresentationSection;