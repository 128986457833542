import isMobile from 'ismobilejs';
import '../css/styles.css';
import '../css/bar-styles.css';
import '../css/icons/ptroiconfont.css';

import PainterroSelecter from './selecter';
import WorkLog from './worklog';
import {
  genId, addDocumentObjectHelpers, trim,
  getScrollbarWidth, distance, logError,
} from './utils';
import PrimitiveTool from './primitive';
import ColorPicker from './colorPicker';
import { setDefaults, setParam } from './params';
import { tr } from './translation';
import ZoomHelper from './zoomHelper';
import EmojiTool from './emoji';
import StampTool from './stamp';
import FloodTool from './flood';
import Resizer from './resizer';
import Inserter from './inserter';
import Settings from './settings';
import ControlBuilder from './controlbuilder';

class PainterroProc {
  constructor(params) {
    addDocumentObjectHelpers();
    this.params = setDefaults(params);
    this.controlBuilder = new ControlBuilder(this);
    this.colorWidgetState = {
      line: {
        target: 'line',
        palleteColor: this.params.activeColor,
        alpha: this.params.activeColorAlpha,
        alphaColor: this.params.activeAlphaColor,
      },
      fill: {
        target: 'fill',
        palleteColor: this.params.activeFillColor,
        alpha: this.params.activeFillColorAlpha,
        alphaColor: this.params.activeFillAlphaColor,
      },
      bg: {
        target: 'bg',
        palleteColor: this.params.backgroundFillColor,
        alpha: this.params.backgroundFillColorAlpha,
        alphaColor: this.params.backgroundFillAlphaColor,
      },
      // stroke: {
      //   target: 'stroke',
      //   palleteColor: this.params.textStrokeColor,
      //   alpha: this.params.textStrokeColorAlpha,
      //   alphaColor: this.params.textStrokeAlphaColor,
      // },
    };
    this.currentBackground = this.colorWidgetState.bg.alphaColor;
    this.currentBackgroundAlpha = this.colorWidgetState.bg.alpha;

    this.tools = [{
      name: 'select',
      activate: () => {
        this.toolContainer.style.cursor = 'crosshair';
        this.select.activate();
        this.select.draw();
      },
      close: () => {
        this.select.close();
        this.toolContainer.style.cursor = 'auto';
      },
      eventListner: () => this.select,
    }, {
      name: 'crop',
      activate: () => {
        this.select.doCrop();
        this.closeActiveTool();
      },
    }, {
      name: 'pixelize',
      activate: () => {
        this.select.doPixelize();
        this.closeActiveTool();
      },
    }, {
      name: 'line',
      controls: [{
        type: 'color',
        title: 'lineColor',
        target: 'line',
        titleFull: 'lineColorFull',
        action: () => {
          this.colorPicker.open(this.colorWidgetState.line);
        },
      }, this.controlBuilder.buildLineWidthControl(1),
      ],
      activate: () => {
        this.toolContainer.style.cursor = 'crosshair';
        this.primitiveTool.activate('line');
      },
      eventListner: () => this.primitiveTool,
    }, {
      name: 'arrow',
      controls: [{
        type: 'color',
        title: 'lineColor',
        target: 'line',
        titleFull: 'lineColorFull',
        action: () => {
          this.colorPicker.open(this.colorWidgetState.line);
        },
      }, this.controlBuilder.buildLineWidthControl(1),
      this.controlBuilder.buildArrowLengthControl(2),
      ],
      activate: () => {
        this.toolContainer.style.cursor = 'crosshair';
        this.primitiveTool.activate('arrow');
      },
      eventListner: () => this.primitiveTool,
    }, {
      name: 'rect',
      controls: [{
        type: 'color',
        title: 'lineColor',
        titleFull: 'lineColorFull',
        target: 'line',
        action: () => {
          this.colorPicker.open(this.colorWidgetState.line);
        },
      }, {
        type: 'color',
        title: 'fillColor',
        titleFull: 'fillColorFull',
        target: 'fill',
        action: () => {
          this.colorPicker.open(this.colorWidgetState.fill);
        },
      }, this.controlBuilder.buildLineWidthControl(2),
      ],
      activate: () => {
        this.toolContainer.style.cursor = 'crosshair';
        this.primitiveTool.activate('rect');
      },
      eventListner: () => this.primitiveTool,
    }, {
      name: 'ellipse',
      controls: [{
        type: 'color',
        title: 'lineColor',
        titleFull: 'lineColorFull',
        target: 'line',
        action: () => {
          this.colorPicker.open(this.colorWidgetState.line);
        },
      }, {
        type: 'color',
        title: 'fillColor',
        titleFull: 'fillColorFull',
        target: 'fill',
        action: () => {
          this.colorPicker.open(this.colorWidgetState.fill);
        },
      }, this.controlBuilder.buildLineWidthControl(2),
      ],
      activate: () => {
        this.toolContainer.style.cursor = 'crosshair';
        this.primitiveTool.activate('ellipse');
      },
      eventListner: () => this.primitiveTool,
    }, {
      name: 'brush',
      controls: [{
        type: 'color',
        title: 'lineColor',
        target: 'line',
        titleFull: 'lineColorFull',
        action: () => {
          this.colorPicker.open(this.colorWidgetState.line);
        },
      }, this.controlBuilder.buildLineWidthControl(1),
      ],
      activate: () => {
        this.toolContainer.style.cursor = 'crosshair';
        this.primitiveTool.activate('brush');
      },
      eventListner: () => this.primitiveTool,
    }, {
      name: 'eraser',
      controls: [this.controlBuilder.buildEraserWidthControl(0),
      ],
      activate: () => {
        this.toolContainer.style.cursor = 'crosshair';
        this.primitiveTool.activate('eraser');
      },
      eventListner: () => this.primitiveTool,
    }, {
      name: 'flood',
      controls: [{
        type: 'color',
        title: 'fillColor',
        titleFull: 'fillColorFull',
        target: 'fill',
        action: () => {
          this.colorPicker.open(this.colorWidgetState.fill);
        },
      }, {
        type: 'int',
        title: 'tolerance',
        titleFull: 'toleranceFull',
        target: 'tolerance',
        min: 0,
        max: 100,
        action: () => {
          const inp = document.getElementById(this.activeTool.controls[1].id).value;
          this.floodTool.setTolerance(inp);
          setParam('tolerance', inp);
        },
        getValue: () => this.floodTool.tolerance,
      },
      ],
      activate: () => {
        this.toolContainer.style.cursor = 'crosshair';
      },
      eventListner: () => this.floodTool,
    }, {
      name: 'stamp',
      controls: [
        {
          type: 'dropdown',
          title: 'stampName',
          titleFull: 'stampNameFull',
          target: 'stampName',
          action: () => {
            const dropdown = document.getElementById(this.activeTool.controls[0].id);
            const stamp = dropdown.value;
            this.stampTool.setStamp(stamp);
          },
          getValue: () => this.stampTool.getStamp(),
          getAvailableValues: () => this.stampTool.stamps,
        },
      ],
      activate: () => {
        this.toolContainer.style.cursor = 'crosshair';
      },
      eventListner: () => this.stampTool,
    }, {
      name: 'emoji',
      controls: [
        this.controlBuilder.buildFontSizeControl(0),
        {
          type: 'btn',
          name: 'Change',
          hint: 'Change Emoji',
          action: () => {
            this.emojiTool.changeEmoji();
          },
        },
      ],
      activate: () => {
        this.toolContainer.style.cursor = 'crosshair';
      },
      close: () => {
        this.emojiTool.close();
      },
      eventListner: () => this.emojiTool,
    }, {
      name: 'rotate',
      activate: () => {
        const { w } = this.size;
        const { h } = this.size;
        const tmpData = this.ctx.getImageData(0, 0, this.size.w, this.size.h);
        const tmpCan = this.doc.createElement('canvas');
        tmpCan.width = w;
        tmpCan.height = h;
        tmpCan.getContext('2d').putImageData(tmpData, 0, 0);
        this.resize(h, w);
        this.ctx.save();
        this.ctx.translate(h / 2, w / 2);
        this.ctx.rotate((90 * Math.PI) / 180);
        this.ctx.drawImage(tmpCan, -w / 2, -h / 2);
        this.adjustSizeFull();
        this.ctx.restore();
        this.worklog.captureState();
        this.closeActiveTool();
      },
    }, {
      name: 'resize',
      activate: () => {
        this.resizer.open();
      },
      close: () => {
        this.resizer.close();
      },
      eventListner: () => this.resizer,
    }, {
      name: 'undo',
      nonStateTool: true,
      activate: () => {
        this.worklog.undoState();
      },
      eventListner: () => this.resizer,
    }, {
      name: 'redo',
      nonStateTool: true,
      activate: () => {
        this.worklog.redoState();
      },
      eventListner: () => this.resizer,
    }, {
      name: 'settings',
      activate: () => {
        this.settings.open();
      },
      close: () => {
        this.settings.close();
      },
      eventListner: () => this.settings,
    }, {
      name: 'save',
      right: true,
      activate: () => {
        this.save();
        this.closeActiveTool();
      },
    }, {
      name: 'open',
      right: true,
      activate: () => {
        this.closeActiveTool();
        const input = document.getElementById('ptro-file-input');
        input.click();
        input.onchange = (event) => {
          const files = event.target.files || event.dataTransfer.files;
          if (!files.length) {
            return;
          }
          this.openFile(files[0]);
          input.value = ''; // to allow reopen
        };
      },
    }, {
      name: 'close',
      right: true,
      activate: () => {
        this.closeActiveTool();
        this.close();
        this.hide();
      },
    }];
    this.isMobile = isMobile.any;
    this.toolByName = {};
    this.tools.forEach((t) => {
      this.toolByName[t.name] = t;
    });
    this.activeTool = undefined;
    this.zoom = false;
    this.ratioRelation = undefined;
    this.id = this.params.id;
    this.saving = false;

    if (this.id === undefined) {
      this.id = genId();
      this.holderId = genId();
      this.holderEl = document.createElement('div');
      this.holderEl.id = this.holderId;
      this.holderEl.className = 'ptro-holder-wrapper';
      document.body.appendChild(this.holderEl);
      this.holderEl.innerHTML = `<div id='${this.id}' class="ptro-holder"></div>`;
      this.baseEl = document.getElementById(this.id);
    } else {
      this.baseEl = document.getElementById(this.id);
      this.holderEl = null;
    }
    let bar = '';
    let rightBar = '';
    this.tools.filter((t) => this.params.hiddenTools.indexOf(t.name) === -1).forEach((b) => {
      const id = genId();
      b.buttonId = id;
      const btn = `<button type="button" class="ptro-icon-btn ptro-color-control" title="${tr(`tools.${b.name}`)}" `
        + `id="${id}" >`
        + `<i class="ptro-icon ptro-icon-${b.name}"></i></button>`;
      if (b.right) {
        rightBar += btn;
      } else {
        bar += btn;
      }
    });

    this.inserter = Inserter.get();

    const cropper = '<div class="ptro-crp-el">'
      + `${PainterroSelecter.code()}</div>`;

    let footerImage = '';
    if (this.params.footerUrl) {
      footerImage = `<img class="ptro-footerimage" src="${this.params.footerUrl}" style="width: 100%; height: ${this.params.footerHeight}px; object-position: top; object-fit: cover;">`;
    }

    this.loadedName = '';
    this.doc = document;
    this.wrapper = this.doc.createElement('div');
    this.wrapper.id = `${this.id}-wrapper`;
    this.wrapper.className = 'ptro-wrapper';
    this.wrapper.innerHTML = '<div class="ptro-scroller">'
        + '<div class="ptro-center-table">'
          + '<div class="ptro-center-tablecell">'
            + `<canvas id="${this.id}-canvas"></canvas>`
            + `<div class="ptro-substrate"></div>${cropper}`
            + `${footerImage}`
          + '</div>'
        + '</div>'
      + `</div>${
        ColorPicker.html()
        + ZoomHelper.html()
        + Resizer.html()
        + Settings.html(this)
        + this.inserter.html()}`;
    this.baseEl.appendChild(this.wrapper);
    this.scroller = this.doc.querySelector(`#${this.id}-wrapper .ptro-scroller`);
    this.bar = this.doc.createElement('div');
    this.bar.id = `${this.id}-bar`;
    this.bar.className = 'ptro-bar ptro-color-main';
    this.bar.innerHTML = `<div><span class="ptro-bar-left">${bar}</span>`
      + '<span class="tool-controls"></span>'
      + `<span class="ptro-bar-right">${rightBar}</span>`
      + '<span class="ptro-info"></span>'
      + '<input id="ptro-file-input" type="file" style="display: none;" accept="image/x-png,image/png,image/gif,image/jpeg" /></div>';
    if (this.isMobile) {
      this.bar.style['overflow-x'] = 'auto';
    }

    this.baseEl.appendChild(this.bar);
    const style = this.doc.createElement('style');
    style.type = 'text/css';
    style.innerHTML = this.params.styles;
    this.baseEl.appendChild(style);

    // this.baseEl.innerHTML = '<iframe class="ptro-iframe"></iframe>';
    // this.iframe = this.baseEl.getElementsByTagName('iframe')[0];
    // this.doc = this.iframe.contentDocument || this.iframe.contentWindow.document;
    // this.doc.body.innerHTML = html;

    this.saveBtn = this.doc.getElementById(this.toolByName.save.buttonId);
    if (this.saveBtn) {
      this.saveBtn.setAttribute('disabled', 'true');
    }
    this.body = this.doc.body;
    this.info = this.doc.querySelector(`#${this.id}-bar .ptro-info`);
    this.canvas = this.doc.querySelector(`#${this.id}-canvas`);
    this.ctx = this.canvas.getContext('2d', { willReadFrequently: true });
    this.toolControls = this.doc.querySelector(`#${this.id}-bar .tool-controls`);
    this.toolContainer = this.doc.querySelector(`#${this.id}-wrapper .ptro-crp-el`);
    this.substrate = this.doc.querySelector(`#${this.id}-wrapper .ptro-substrate`);
    this.zoomHelper = new ZoomHelper(this);
    this.select = new PainterroSelecter(this, (notEmpty) => {
      [this.toolByName.crop, this.toolByName.pixelize].forEach((c) => {
        this.setToolEnabled(c, notEmpty);
      });
    });
    this.resizer = new Resizer(this);
    this.settings = new Settings(this);
    this.primitiveTool = new PrimitiveTool(this);
    this.primitiveTool.setLineWidth(this.params.defaultLineWidth);
    this.primitiveTool.setArrowLength(this.params.defaultArrowLength);
    this.primitiveTool.setEraserWidth(this.params.defaultEraserWidth);
    this.primitiveTool.setPixelSize(this.params.defaultPixelSize);
    this.worklog = new WorkLog(this, (state) => {
      if (this.saveBtn && !state.initial) {
        this.saveBtn.removeAttribute('disabled');
      }
      this.setToolEnabled(this.toolByName.undo, !state.first);
      this.setToolEnabled(this.toolByName.redo, !state.last);
      if (this.params.changeHandler) {
        this.params.changeHandler.call(this, {
          image: this.imageSaver,
          operationsDone: this.worklog.current.prevCount,
          realesedMemoryOperations: this.worklog.clearedCount,
          selection: this.select.imagePlaced,
        });
      }
    });
    this.inserter.init(this);
    this.emojiTool = new EmojiTool(this, params.customEmojis);
    this.stampTool = new StampTool(this, params.stamps);
    this.floodTool = new FloodTool(this);
    this.colorPicker = new ColorPicker(this, (widgetState) => {
      this.colorWidgetState[widgetState.target] = widgetState;
      this.doc.querySelector(
        `#${this.id} .ptro-color-btn[data-id='${widgetState.target}']`,
      ).style['background-color'] = widgetState.alphaColor;
      if (widgetState.target === 'line') {
        setParam('activeColor', widgetState.palleteColor);
        setParam('activeColorAlpha', widgetState.alpha);
      } else if (widgetState.target === 'fill') {
        setParam('activeFillColor', widgetState.palleteColor);
        setParam('activeFillColorAlpha', widgetState.alpha);
      } else if (widgetState.target === 'bg') {
        setParam('backgroundFillColor', widgetState.palleteColor);
        setParam('backgroundFillColorAlpha', widgetState.alpha);
      } else if (widgetState.target === 'stroke') {
        setParam('textStrokeColor', widgetState.palleteColor);
        setParam('textStrokeColorAlpha', widgetState.alpha);
      }
    });

    this.defaultTool = this.toolByName[this.params.defaultTool] || this.toolByName.select;

    this.tools.filter((t) => this.params.hiddenTools.indexOf(t.name) === -1).forEach((b) => {
      this.getBtnEl(b).onclick = () => {
        if (b === this.defaultTool && this.activeTool === b) {
          return;
        }
        const currentActive = this.activeTool;
        if (b.nonStateTool) {
          b.activate();
        } else {
          this.closeActiveTool(true);
          if (currentActive !== b) {
            this.setActiveTool(b);
          } else {
            this.setActiveTool(this.defaultTool);
          }
        }
      };
      this.getBtnEl(b).ontouch = this.getBtnEl(b).onclick;
    });

    this.setActiveTool(this.defaultTool);

    this.imageSaver = {
      /**
       * Returns image as base64 data url
       * @param {string} type - type of data url, default image/png
       * @param {string} quality - number from 0 to 1, works for `image/jpeg` or `image/webp`
       */
      asDataURL: (type, quality) => {
        let realType = type;
        if (realType === undefined) {
          realType = 'image/png';
        }
        return this.getAsUri(realType, quality);
      },
      asBlob: (type, quality) => {
        let realType = type;
        if (realType === undefined) {
          realType = 'image/png';
        }
        const uri = this.getAsUri(realType, quality);
        const byteString = atob(uri.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i += 1) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], {
          type: realType,
        });
      },
      suggestedFileName: (type) => {
        let realType = type;
        if (realType === undefined) {
          realType = 'png';
        }
        return `${(this.loadedName || `image-${genId()}`)}.${realType}`;
      },
    };

    this.initEventHandlers();
    this.hide();
    this.zoomFactor = 1;
  }

  setToolEnabled(tool, state) {
    const btn = this.doc.getElementById(tool.buttonId);
    if (btn) {
      if (state) {
        btn.removeAttribute('disabled');
      } else {
        btn.setAttribute('disabled', 'true');
      }
    }
  }

  getAsUri(type, quality) {
    let realQuality = quality;
    if (realQuality === undefined) {
      realQuality = 0.92;
    }
    return this.canvas.toDataURL(type, realQuality);
  }

  getBtnEl(b) {
    return this.doc.getElementById(b.buttonId);
  }

  save() {
    if (this.saving) {
      return this;
    }
    this.saving = true;
    const btn = this.doc.getElementById(this.toolByName.save.buttonId);
    const icon = this.doc.querySelector(`#${this.toolByName.save.buttonId} > i`);
    if (btn) {
      btn.setAttribute('disabled', 'true');
    }
    if (icon) {
      icon.className = 'ptro-icon ptro-icon-loading ptro-spinning';
    }

    if (this.select.imagePlaced) {
      this.select.finishPlacing();
    }

    if (this.params.saveHandler !== undefined) {
      this.params.saveHandler(this.imageSaver, (hide) => {
        if (hide === true) {
          this.hide();
        }
        if (icon) {
          icon.className = 'ptro-icon ptro-icon-save';
        }
        this.saving = false;
      });
    } else {
      logError('No saveHandler defined, please check documentation');
      if (icon) {
        icon.className = 'ptro-icon ptro-icon-save';
      }
      this.saving = false;
    }
    return this;
  }

  close() {
    if (this.params.closeHandler !== undefined) {
      this.params.closeHandler();
    }
  }

  closeActiveTool(doNotSelect) {
    if (this.activeTool !== undefined) {
      if (this.activeTool.close !== undefined) {
        this.activeTool.close();
      }
      this.toolControls.innerHTML = '';
      const btnEl = this.getBtnEl(this.activeTool);
      if (btnEl) {
        btnEl.className = this.getBtnEl(this.activeTool).className.replace(' ptro-color-active-control', '');
      }
      this.activeTool = undefined;
    }
    if (doNotSelect !== true) {
      this.setActiveTool(this.defaultTool);
    }
  }

  handleToolEvent(eventHandler, event) {
    if (this.activeTool && this.activeTool.eventListner) {
      const listner = this.activeTool.eventListner();
      if (listner[eventHandler]) {
        listner[eventHandler](event);
      }
    }
  }

  initEventHandlers() {
    this.documentHandlers = {
      mousedown: (e) => {
        if (!this.baseEl.contains(e.target)) return;
        if (this.shown) {
          if (this.worklog.empty
             && (e.target.className.indexOf('ptro-crp-el') !== -1
              || e.target.className.indexOf('ptro-icon') !== -1
              || e.target.className.indexOf('ptro-named-btn') !== -1)) {
            this.clearBackground(); // clear initText
          }
          if (this.colorPicker.handleMouseDown(e) !== true) {
            this.handleToolEvent('handleMouseDown', e);
          }
        }
      },
      touchstart: (e) => {
        if (e.touches.length === 1) {
          e.clientX = e.changedTouches[0].clientX;
          e.clientY = e.changedTouches[0].clientY;
          this.documentHandlers.mousedown(e);
        } else if (e.touches.length === 2) {
          const fingersDist = distance({
            x: e.changedTouches[0].clientX,
            y: e.changedTouches[0].clientY,
          }, {
            x: e.changedTouches[1].clientX,
            y: e.changedTouches[1].clientY,
          });
          this.lastFingerDist = fingersDist;
        }
      },
      touchend: (e) => {
        e.clientX = e.changedTouches[0].clientX;
        e.clientY = e.changedTouches[0].clientY;
        this.documentHandlers.mouseup(e);
      },
      touchmove: (e) => {
        if (e.touches.length === 1) {
          e.clientX = e.changedTouches[0].clientX;
          e.clientY = e.changedTouches[0].clientY;
          this.documentHandlers.mousemove(e);
        } else if (e.touches.length === 2) {
          const fingersDist = distance({
            x: e.changedTouches[0].clientX,
            y: e.changedTouches[0].clientY,
          }, {
            x: e.changedTouches[1].clientX,
            y: e.changedTouches[1].clientY,
          });

          if (fingersDist > this.lastFingerDist) {
            e.wheelDelta = 1;
            e.ctrlKey = true;
            this.documentHandlers.mousewheel(e);
          } else if (fingersDist > this.lastFingerDist) {
            e.wheelDelta = -1;
            e.ctrlKey = true;
            this.documentHandlers.mousewheel(e);
          }
          this.lastFingerDist = fingersDist;
          e.stopPropagation();
          e.preventDefault();
        }
      },
      mousemove: (e) => {
        if (this.shown) {
          this.handleToolEvent('handleMouseMove', e);
          this.colorPicker.handleMouseMove(e);
          this.zoomHelper.handleMouseMove(e);
          this.curCord = [
            (e.clientX - this.elLeft()) + this.scroller.scrollLeft,
            (e.clientY - this.elTop()) + this.scroller.scrollTop,
          ];
          const scale = this.getScale();
          this.curCord = [this.curCord[0] * scale, this.curCord[1] * scale];
          if (e.target.tagName.toLowerCase() !== 'input' && e.target.tagName.toLowerCase() !== 'button'
        && e.target.tagName.toLowerCase() !== 'i' && e.target.tagName.toLowerCase() !== 'select') {
            e.preventDefault();
          }
        }
      },
      mouseup: (e) => {
        if (this.shown) {
          this.handleToolEvent('handleMouseUp', e);
          this.colorPicker.handleMouseUp(e);
        }
      },
      mousewheel: () => {
      },
      keydown: (e) => {
        if (this.shown) {
          this.colorPicker.handleKeyDown(e);
          const evt = window.event ? window.event : e;
          this.handleToolEvent('handleKeyDown', evt);
        }
      },
      paste: () => {
      },
    };

    this.windowHandlers = {
      resize: () => {
        if (this.shown) {
          this.adjustSizeFull();
          this.syncToolElement();
        }
      },
    };
    this.listenersInstalled = false;
  }

  attachEventHandlers() {
    if (this.listenersInstalled) {
      return;
    }
    Object.keys(this.documentHandlers).forEach((key) => {
      if (window.PointerEvent && key.includes('touch')) return;
      const k = window.PointerEvent ? key.replace('mouse', 'pointer') : key;
      this.doc.addEventListener(k, this.documentHandlers[key]);
    });

    Object.keys(this.windowHandlers).forEach((key) => {
      window.addEventListener(key, this.windowHandlers[key]);
    });
    this.listenersInstalled = true;
  }

  removeEventHandlers() {
    if (!this.listenersInstalled) {
      return;
    }
    Object.keys(this.documentHandlers).forEach((key) => {
      this.doc.removeEventListener(key, this.documentHandlers[key]);
    });
    Object.keys(this.windowHandlers).forEach((key) => {
      window.removeEventListener(key, this.windowHandlers[key]);
    });

    this.listenersInstalled = false;
  }

  elLeft() {
    return this.toolContainer.documentOffsetLeft + this.scroller.scrollLeft;
  }

  elTop() {
    return this.toolContainer.documentOffsetTop + this.scroller.scrollTop;
  }

  fitImage(img) {
    this.resize(img.naturalWidth, img.naturalHeight);
    this.ctx.drawImage(img, 0, 0);
    this.zoomFactor = (this.wrapper.documentClientHeight / this.size.h) - 0.2;
    this.adjustSizeFull();
    this.worklog.captureState();
  }

  loadImage(source) {
    this.inserter.handleOpen(source);
  }

  pasteInto(url) {
    const opts = {
      x: this.size.w / 2,
      y: this.size.h / 2,
      nextTool: 'brush',
    };
    this.inserter.handleStamp(url, opts);
  }

  insertInto(url, opts) {
    this.inserter.handleInsert(url, opts);
  }

  show(openImage) {
    this.shown = true;
    this.scrollWidth = getScrollbarWidth();
    if (this.isMobile) {
      this.origOverflowY = this.body.style['overflow-y'];
      if (this.params.fixMobilePageReloader) {
        this.body.style['overflow-y'] = 'hidden';
      }
    }
    this.baseEl.removeAttribute('hidden');
    if (this.holderEl) {
      this.holderEl.removeAttribute('hidden');
    }
    if (typeof openImage === 'string') {
      this.loadedName = trim(
        (openImage.substring(openImage.lastIndexOf('/') + 1) || '').replace(/\..+$/, ''),
      );

      this.loadImage(openImage);
    } else if (openImage !== false) {
      this.clear();
    }
    this.attachEventHandlers();
    return this;
  }

  hide() {
    if (this.isMobile) {
      this.body.style['overflow-y'] = this.origOverflowY;
    }
    this.shown = false;
    this.baseEl.setAttribute('hidden', '');
    if (this.holderEl) {
      this.holderEl.setAttribute('hidden', '');
    }
    this.removeEventHandlers();
    return this;
  }

  openFile(f) {
    if (!f) {
      return;
    }
    this.loadedName = trim((f.name || '').replace(/\..+$/, ''));
    const dataUrl = URL.createObjectURL(f);
    this.loadImage(dataUrl);
  }

  getScale() {
    return this.canvas.getAttribute('width') / this.canvas.offsetWidth;
  }

  adjustSizeFull() {
    const ratio = this.wrapper.documentClientWidth / this.wrapper.documentClientHeight;

    if (this.zoom === false) {
      if (this.size.w > this.wrapper.documentClientWidth
        || this.size.h > this.wrapper.documentClientHeight) {
        const newRelation = ratio < this.size.ratio;
        this.ratioRelation = newRelation;
        if (newRelation) {
          this.canvas.style.width = `${this.wrapper.clientWidth}px`;
          this.canvas.style.height = 'auto';
        } else {
          this.canvas.style.width = 'auto';
          this.canvas.style.height = `${this.wrapper.clientHeight}px`;
        }
        this.scroller.style.overflow = 'hidden';
      } else {
        this.scroller.style.overflow = 'hidden';
        this.canvas.style.width = 'auto';
        this.canvas.style.height = 'auto';
        this.ratioRelation = 0;
      }
    } else {
      this.scroller.style.overflow = 'scroll';
      this.canvas.style.width = `${this.size.w * this.zoomFactor}px`;
      this.canvas.style.height = `${this.size.h * this.zoomFactor}px`;
      this.ratioRelation = 0;
    }
    this.syncToolElement();
    this.select.draw();
  }

  resize(x, y) {
    this.size = {
      w: x,
      h: y,
      ratio: x / y,
    };
    this.canvas.setAttribute('width', this.size.w);
    this.canvas.setAttribute('height', this.size.h);
  }

  syncToolElement() {
    const w = Math.round(this.canvas.documentClientWidth);
    const l = this.canvas.offsetLeft;
    const h = Math.round(this.canvas.documentClientHeight);
    const t = this.canvas.offsetTop;
    this.toolContainer.style.left = `${l}px`;
    this.toolContainer.style.width = `${w}px`;
    this.toolContainer.style.top = `${t}px`;
    this.toolContainer.style.height = `${h}px`;
    this.substrate.style.left = `${l}px`;
    this.substrate.style.width = `${w}px`;
    this.substrate.style.top = `${t}px`;
    this.substrate.style.height = `${h}px`;
  }

  clear() {
    const w = this.params.defaultSize.width === 'fill' ? this.wrapper.clientWidth : this.params.defaultSize.width;
    const h = this.params.defaultSize.height === 'fill' ? this.wrapper.clientHeight : this.params.defaultSize.height;
    this.resize(w, h);
    this.clearBackground();
    this.worklog.captureState(true);
    this.worklog.clean = true;
    this.syncToolElement();
    this.adjustSizeFull();
  }

  clearBackground() {
    this.ctx.beginPath();
    this.ctx.clearRect(0, 0, this.size.w, this.size.h);
    this.ctx.rect(0, 0, this.size.w, this.size.h);
    this.ctx.fillStyle = this.currentBackground;
    this.ctx.fill();
  }

  setActiveTool(b) {
    this.activeTool = b;
    const btnEl = this.getBtnEl(this.activeTool);
    if (btnEl) {
      btnEl.className += ' ptro-color-active-control';
    }
    let ctrls = '';
    (b.controls || []).forEach((ctl) => {
      ctl.id = genId();
      if (ctl.title) {
        ctrls += `<span class="ptro-tool-ctl-name" title="${tr(ctl.titleFull)}">${tr(ctl.title)}</span>`;
      }
      if (ctl.type === 'btn') {
        ctrls += `<button type="button" ${ctl.hint ? `title="${ctl.hint}"` : ''} class="ptro-color-control ${ctl.icon ? 'ptro-icon-btn' : 'ptro-named-btn'}" `
          + `id=${ctl.id}>${ctl.icon ? `<i class="ptro-icon ptro-icon-${ctl.icon}"></i>` : ''}`
          + `<p>${ctl.name || ''}</p></button>`;
      } else if (ctl.type === 'color') {
        ctrls += `<button type="button" id=${ctl.id} data-id='${ctl.target}' `
          + `style="background-color: ${this.colorWidgetState[ctl.target].alphaColor}" `
          + 'class="color-diwget-btn ptro-color-btn ptro-bordered-btn"></button>'
          + '<span class="ptro-btn-color-checkers-bar"></span>';
      } else if (ctl.type === 'int') {
        ctrls += `<input id=${ctl.id} class="ptro-input" type="number" min="${ctl.min}" max="${ctl.max}" `
          + `data-id='${ctl.target}'/>`;
      } else if (ctl.type === 'dropdown') {
        let options = '';
        ctl.getAvailableValues().forEach((o) => {
          options += `<option ${o.extraStyle ? `style='${o.extraStyle}'` : ''}`
            + ` value='${o.value}' ${o.title ? `title='${o.title}'` : ''}>${o.name}</option>`;
        });
        ctrls += `<select id=${ctl.id} class="ptro-input" `
          + `data-id='${ctl.target}'>${options}</select>`;
      }
    });
    this.toolControls.innerHTML = ctrls;
    (b.controls || []).forEach((ctl) => {
      if (ctl.type === 'int') {
        this.doc.getElementById(ctl.id).value = ctl.getValue();
        this.doc.getElementById(ctl.id).oninput = ctl.action;
      } else if (ctl.type === 'dropdown') {
        this.doc.getElementById(ctl.id).onchange = ctl.action;
        this.doc.getElementById(ctl.id).value = ctl.getValue();
      } else {
        this.doc.getElementById(ctl.id).onclick = ctl.action;
      }
    });
    b.activate();
  }
}

module.exports = (params) => new PainterroProc(params);
