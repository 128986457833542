import React, { useEffect, useState } from 'react';
import { Howl } from 'howler';
import { imageUrl, KEYS } from '../GameData.js';
import PageEntryText from './PageEntryText.js';
import PageEntryImage from './PageEntryImage.js';

const ActivePage = ({ item, handleFinalSubmit, options }) => {
  useEffect(() => {
    new Howl({ src: ['audio/new.mp3'], volume: 0.5 }).play();
  }, []);

  
  const [nextAction, setNextAction] = useState("sendOther");
  const hasControls = options && parseInt(options[KEYS.moves]) === 0;

  const handleOptionChange = (e) => {
    setNextAction(e.target.value);
  }

  // TODO: style controls

  let entryItem = <span>Move type error: {item[KEYS.moveType]} : {item.key}</span>;
  let towerLowerUrl = (item.isTower && item.previousPage) ? imageUrl(item.previousPage.key) : (item.isTower ? false : null);
  const props = { item, handleFinalSubmit, options, towerLowerUrl, nextAction };
  if(item[KEYS.moveType] === 'text') {
      entryItem = <PageEntryText {...props} />;
  }
  if(item[KEYS.moveType] === 'image') {
      entryItem = <PageEntryImage {...props} />;
  }

  let previousItem = <span className="firstItemDescription">(This is the first entry in the book! Just make something up.)</span>
  if(item.isTower) {
    if(item.previousPage) {
      previousItem = <span className="firstItemDescription">The bottom of this image came from the previous user. The next person will draw on top of this, and only see the top part.</span>;
    }
    else {
      previousItem = <span className="firstItemDescription">This is the first drawing in the tower! The next person will draw on top of this, and only see the top part.</span>
    }
  }
  else {
    if(item.previousPage && item.previousPage.text) {
        previousItem = <span>{item.previousPage.text}</span>;
    }
    if(item.previousPage && item.previousPage[KEYS.moveType] === "image") {
        previousItem = <span><img className="pageImage" alt="page" src={ imageUrl(item.previousPage.key) } /></span>;
    }
  }

  return <ul className={`${item.isBigImage ? 'bigimage' : ''} moveSet rounded`}>
  <li className="moveRow">
    <span className="moveUser">Previous</span>
    {previousItem}
  </li>
  <li className="moveRow moveRowYou">
    <span className="moveUser">
    {item.isLast ? <span className="moveIsLast">Last page!</span> : null}You</span>
    {entryItem}
    { hasControls && 
    <div className="sendOptions">
      <input type="radio" name="todoNext" value="sendOther" id="sendOther" checked={nextAction === "sendOther"} onChange={handleOptionChange}/>
      <label htmlFor="sendOther">Send to another player</label>
      <input type="radio" name="todoNext" value="sendSelf" id="sendSelf" checked={nextAction === "sendSelf"} onChange={handleOptionChange}/>
      <label htmlFor="sendSelf">Send back to yourself</label>
      <input type="radio" name="todoNext" value="forceEnd" id="forceEnd" checked={nextAction === "forceEnd"} onChange={handleOptionChange}/>
      <label htmlFor="forceEnd">End the book</label>
    </div>
    }
  </li></ul>;
};

export default ActivePage;