import './BookCreator.css';
import React, { useState } from 'react';
import { useBookCreatorData, usePledge } from '../GameData';
import patreonImg from '../img/Patreon_Round.png';

const BookCreator = () => {
  const [firstType, setFirstType] = useState("text");
  const [nextType, setNextType] = useState("alternate");
  const [special, setSpecial] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [generateStarter, setGenerateStarter] = useState(false);
  const [textLength, setTextLength] = useState(70);
  const [moveCount, setMoveCount] = useState(1);
  const [bookCount, setBookCount] = useState(0);
  const [timerForText, setTimerForText] = useState(1);
  const [timerForImage, setTimerForImage] = useState(1);
  const { createBookData, users } = useBookCreatorData();
  const pledgeAmount = usePledge();
  const [preassignedPlayer, setPreassignedPlayer] = useState("");
  const [preassignedMoves, setPreassignedMoves] = useState({});

  const activePlayers = users.filter(u => u.isActive && u.accessLevel >= 40);
  const playerCount = activePlayers.length;

  const createBooks = () => {
    const opts = {firstType, nextType, special, textLength, generateStarter};
    if(moveCount !== 1) {
      opts.moveCount = moveCount;
    }
    if(bookCount !== 0) {
      opts.bookCount = bookCount;
    }
    if(timerForText !== 1) {
      opts.timerForText = stepToSeconds(timerForText);
    }
    if(timerForImage !== 1) {
      opts.timerForImage = stepToSeconds(timerForImage);
    }
    if(preassignedPlayer && preassignedPlayer.length > 0) {
      opts.preassignedPlayer = preassignedPlayer;
      opts.preassignedMoves = preassignedMoves;
    }
    createBookData(opts);
  };

  const stepToSeconds = (step) => {
    if(step === 1) return 0;
    if(step <= 12) return step * 5;
    if(step <= 18) return ((step - 12) * 30) + 60;
    return ((step - 18) * 60) + 240; 
  }
  const secondsToText = (seconds) => {
    if(seconds === 0) return `No time limit`;
    let text = ``;
    if(seconds >= 60) text = `${Math.floor(seconds / 60)} minute${Math.floor(seconds / 60) === 1 ? '' : 's'} `;
    if(seconds >= 60 && seconds % 60 === 30) text = `${Math.floor(seconds / 60)}½ minutes`;
    else if(seconds % 60 !== 0) text = `${text}${seconds % 60} second${seconds % 60 === 1 ? '' : 's'}`
    return text.trim();
  }

  // eslint-disable-next-line
  const newLabel = <span className="newLabel">New!</span>;

  const isBetaTester = pledgeAmount >= 350;
  const betaTesterLock = isBetaTester ? null :
  <div className="betaLocked"><img src={patreonImg} alt="Patreon logo" />Early access for <a href="https://patreon.com/stochaztic">Beta Tester tier Patrons</a></div>;

  const numMoves = moveCount === 0 ? 10 : moveCount === 1 ? playerCount : moveCount;
  const showPreassigned = isBetaTester;
  const preassigned = <div>
    <h5>Preassign pages: { newLabel }</h5>
    <select value={preassignedPlayer} onChange={(e) => {
      setPreassignedPlayer(e.target.value);
    }}>
      <option key="none" value="">None</option>
      {activePlayers.map(p => <option key={p.key} value={p.key}>{p.displayName}</option>)}
    </select>
    <div>
      {[...Array(numMoves)].map((_,i) => i + 1).map(i => <span
       key={i} className={preassignedMoves[i] ? "moveClick on" : "moveClick off"} onClick={() => {
          setPreassignedMoves({...preassignedMoves, [i]: !preassignedMoves[i]})
        }}>
          {i}
      </span>)}
    </div>
  </div>;

  return (
  <div className="bookCreationSection rounded">
    <h4>New Book Settings</h4>
    <section className={special === "tower" ? "disableSection" : ""}>
      <div>
        <h5>First page:</h5>
        <ul className="bookCreationList">
          <li onClick={() => {setFirstType("alternate");}} className={firstType === 'alternate' ? 'isChosen' : ''}>Random</li>
          <li onClick={() => setFirstType("text")} className={firstType === 'text' ? 'isChosen' : ''}>Write</li>
          <li onClick={() => {setFirstType("image");}} className={firstType === 'image' ? 'isChosen' : ''}>Draw</li>
        </ul>
      </div>
      <div>
      <h5>Following pages:</h5>
        <ul className="bookCreationList">
          <li onClick={() => setNextType("alternate")} className={nextType === 'alternate' ? 'isChosen' : ''}>Alternate</li>
          <li onClick={() => setNextType("text")} className={nextType === 'text' ? 'isChosen' : ''}>Write</li>
          <li onClick={() => setNextType("image")} className={nextType === 'image' ? 'isChosen' : ''}>Draw</li>
        </ul>
      </div>
    </section>
    <h4 className="optionsHeader" onClick={() => setShowOptions(!showOptions)}>{ showOptions ? '▼' : '▶' }
      &nbsp;Additional Options<span className="disclosure">Click to { showOptions ? 'hide' : 'show' }</span></h4>
    { showOptions && <>
      <p>Some features are currently in beta testing, and require connecting a pledge <a href="https://patreon.com/stochaztic">via Patreon</a> to access.</p>
      <section>
        <div>
          <h5>Timer for writing:</h5>
          <input name="timerForTextInput" type="range" min={1} max={24} step={1}
            defaultValue={timerForText}
            onChange={e => setTimerForText(parseInt(e.target.value))}
            onMouseUp={e => setTimerForText(parseInt(e.target.value))}
          />
          <label htmlFor="timerForTextInput">{ secondsToText(stepToSeconds(timerForText)) }</label>
        </div>
        <div>
          <h5>Timer for drawing:</h5>
          <input name="timerForImageInput" type="range" min={1} max={39} step={1}
            defaultValue={timerForImage}
            onChange={e => setTimerForImage(parseInt(e.target.value))}
            onMouseUp={e => setTimerForImage(parseInt(e.target.value))}
          />
          <label htmlFor="timerForImageInput">{ secondsToText(stepToSeconds(timerForImage)) }</label>
        </div>
        <div className={(!isBetaTester || special === "tower") ? "disableSection" : ""}>
          <h5>Generate first phrase or drawing:</h5>
          { betaTesterLock }
          <ul className="bookCreationList">
            <li onClick={() => setGenerateStarter(true)} className={generateStarter === true ? 'isChosen' : ''}>On</li>
            <li onClick={() => setGenerateStarter(false)} className={generateStarter === false ? 'isChosen' : ''}>Off</li>
          </ul>
        </div>
        <div>
          <h5>Special mode:</h5>
          { betaTesterLock }
          <ul className="bookCreationList">
            <li onClick={() => setSpecial("")} className={special === '' ? 'isChosen' : ''}>None</li>
            <li onClick={() => {setGenerateStarter(false); setSpecial("tower");}} className={special === 'tower' ? 'isChosen' : ''}>Tower</li>
            <li onClick={() => setSpecial("bigimage")} className={`${!isBetaTester ? "disableItem" : ""} ${special === 'bigimage' ? 'isChosen' : ''}`}>Big Images</li>
          </ul>
        </div>
        <div>
          <h5>Text max length:</h5>
          <input name="textLengthInput" type="range" min={1} max={180} step={1}
            defaultValue={textLength}
            onChange={e => setTextLength(parseInt(e.target.value))}
            onMouseUp={e => setTextLength(parseInt(e.target.value))}
          />
          <label htmlFor="textLengthInput">{ textLength }</label>
        </div>
        <div>
          <h5>Number of books:</h5>
          <input name="bookCountInput" type="range" min={0} max={12} step={1}
            defaultValue={bookCount}
            onChange={e => setBookCount(parseInt(e.target.value))}
            onMouseUp={e => setBookCount(parseInt(e.target.value))}
          />
          <label htmlFor="bookCountInput">{
            bookCount === 0 ? `One book per player` : `${bookCount} book${bookCount === 1 ? '' : 's'} total` 
          }</label>
        </div>
        <div className={!isBetaTester ? "disableSection" : ""}>
          <h5>Pages per book:</h5>
          { betaTesterLock }
          <input name="moveCountInput" type="range" min={0} max={16} step={1}
            defaultValue={moveCount}
            onChange={e => setMoveCount(parseInt(e.target.value))}
            onMouseUp={e => setMoveCount(parseInt(e.target.value))}
          />
          <label htmlFor="moveCountInput">{
            moveCount === 0 ? `Page author decides` :
            moveCount === 1 ? `One page per player` : `${moveCount} page${moveCount === 1 ? '' : 's'} total` 
          }</label>
        </div>
        { showPreassigned && preassigned }
      </section>
      </> }
    <button onClick={createBooks}>Create Books for { playerCount } Player{playerCount === 1 ? '' : 's'}</button>
  </div>
  );
};
  
export default BookCreator;