import React from 'react';
import { useUserSelf } from '../GameData';
import { TwitterPicker } from 'react-color';

const UserColorChanger = () => {
  const userSelf = useUserSelf();

  const handleChangeComplete = (color) => {
    userSelf.ref.child("color").set(color.hex);
  };

  return (
    <TwitterPicker triangle="hide"
      color= { userSelf.color || '#25ACEB' }
      onChangeComplete= { handleChangeComplete }
    />
  );
};
  
export default UserColorChanger;