import React, { useState } from 'react';
import { useUser } from 'reactfire';

const LocalGameContext = React.createContext([{}, () => {}]);

const LocalGameProvider = (props) => {
  const { data: user }  = useUser();
  const [state, setState] = useState({});
  return (
    <LocalGameContext.Provider value={[user, state, setState]}>
      {props.children}
    </LocalGameContext.Provider>
  );
}

export { LocalGameContext, LocalGameProvider };